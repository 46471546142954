cloud-shareinfo-dialog {

    .cloud-share-info__scrollable-table-area {
        max-height: 153px;
        min-height: auto;
        height: auto;
        overflow-y: auto;
        border: 1px solid #c2c2c2;
        border-radius: 4px;
        cursor: default;
    }

    .cloud-share-info__scrollable-table {
        margin-bottom: 0;
    }

    .cloud-share-info__svg-icon {
        height: 24px;
        width: 24px;
    }

    .cloud-share-info__table-row:hover {
        background-color: transparent !important;
        cursor: default;
    }

    .cloud-share-info__table-svg{
        padding: 8px;
        width: 24px;
        height: 24px;
    }

    ul.cloud-share-info__name-input-list-errors {
        min-height: $poseidon-svg-dimension;
        margin: 8px 0 16px;
        padding-left: 4px;
        list-style-type: none;
    }

    .cloud-share-info__label-collection {
        padding-bottom: 3px;

        &.file-list {
            margin-top: 8px;
        }
    }

    .cloud-shareinfo__icon-container {
        display: flex;
        justify-content: center;
        margin-bottom: 24px;
    }

    .upper-icon {
        display: flex;
        align-content: center;
        width: 48px;
        height: 48px;
        line-height: 40px;
        vertical-align: baseline;
        fill: $color-h3;
    }

    .cloud-shareinfo__text {
        font-size: $cloud-font-size-large;
        text-align: center;
    }

    .cloud-shareinfo__text2 {
        // margin-bottom: 16px;
        padding: $cloud-base-padding;
        line-height: $cloud-base-150pct;
        text-align: center;

        &.collection {
            text-align: left;
        }
    }

    .cloud-shareinfo__collection-label {
        text-align: left !important;
    }

    .cloud-shareinfo__folder-name {
        display: inline-block;
        max-width: 250px;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: bottom;
    }

    .cloud-shareinfo__link-container {
        margin-bottom: 16px;
        padding: $cloud-base-padding * 1.5;
        background-color: $color-b-l94;
        line-height: $cloud-base-150pct;
        text-align: center;
        text-overflow: ellipsis;
        overflow: hidden;

        .cloud-shareinfo__link {
            display: block;
            padding-bottom: $cloud-base-padding;
            color: $black;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .cloud-shareinfo__icon {
        width: 24px;
        margin-right: $cloud-base-padding;
        background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyMCIgaGVpZ2h0PSIyMCIgdmlld0JveD0iMCAwIDIwIDIwIj48ZGVmcz48c3R5bGU+LmF7ZmlsbDojQzFDMUMxO308L3N0eWxlPjwvZGVmcz48dGl0bGU+c2V0dGluZ3NfMjA8L3RpdGxlPjxwYXRoIGNsYXNzPSJhIiBkPSJNMTkuMzE1LDguMmwtMS43NDQtLjUzMkEuOTY4Ljk2OCwwLDAsMSwxNyw2LjI5MmwuODU3LTEuNjA5YS45NjguOTY4LDAsMCwwLS4xNy0xLjEzOWwtMS4yMy0xLjIzYS45NjcuOTY3LDAsMCwwLTEuMTM5LS4xN0wxMy43MDgsM2EuOTY4Ljk2OCwwLDAsMS0xLjM4LS41NzJMMTEuOC42ODVBLjk2OC45NjgsMCwwLDAsMTAuODcsMEg5LjEzQS45NjguOTY4LDAsMCwwLDguMi42ODVMNy42NzIsMi40MjlBLjk2OC45NjgsMCwwLDEsNi4yOTIsM0w0LjY4MywyLjE0NGEuOTY4Ljk2OCwwLDAsMC0xLjEzOS4xN2wtMS4yMywxLjIzYS45NjguOTY4LDAsMCwwLS4xNywxLjEzOUwzLDYuMjkyYS45NjguOTY4LDAsMCwxLS41NzIsMS4zOEwuNjg1LDguMkEuOTY4Ljk2OCwwLDAsMCwwLDkuMTN2MS43NGEuOTY4Ljk2OCwwLDAsMCwuNjg1LjkyNWwxLjc0NC41MzJBLjk2OC45NjgsMCwwLDEsMywxMy43MDhsLS44NTcsMS42MDlhLjk2OC45NjgsMCwwLDAsLjE3LDEuMTM5bDEuMjMsMS4yM2EuOTY4Ljk2OCwwLDAsMCwxLjEzOS4xN0w2LjI5MiwxN2EuOTY4Ljk2OCwwLDAsMSwxLjM4LjU3Mkw4LjIsMTkuMzE1QS45NjguOTY4LDAsMCwwLDkuMTMsMjBoMS43NGEuOTY4Ljk2OCwwLDAsMCwuOTI1LS42ODVsLjUzMi0xLjc0NEEuOTY4Ljk2OCwwLDAsMSwxMy43MDgsMTdsMS42MDkuODU3YS45NjguOTY4LDAsMCwwLDEuMTM5LS4xN2wxLjIzLTEuMjNhLjk2OC45NjgsMCwwLDAsLjE3LTEuMTM5TDE3LDEzLjcwOGEuOTY4Ljk2OCwwLDAsMSwuNTcyLTEuMzhsMS43NDQtLjUzMkEuOTY3Ljk2NywwLDAsMCwyMCwxMC44N1Y5LjEzQS45NjguOTY4LDAsMCwwLDE5LjMxNSw4LjJaTTEwLDE0LjE2N0E0LjE2Nyw0LjE2NywwLDEsMSwxNC4xNjcsMTAsNC4xNjcsNC4xNjcsMCwwLDEsMTAsMTQuMTY3WiIvPjwvc3ZnPg==') center no-repeat;
    }

    .cloud-shareinfo__edit-container {
        display: flex;
        justify-content: center;
    }

    .cloud-shareinfo__collection-name-container{
        margin: 12px 0;
        position: relative;
        display: flex;
        align-items: center;
        border: 1px solid lightgray;
        padding-right: 46px;
        border-radius: 4px;

        pos-input {
            width: 100%;
            border: none;
            input {
                border: none;
            }
        }

        button {
            position: absolute;
            right: 4px;
        }
    }

    .cloud-share-info__edit-icon {
        width: 24px;
        height: 24px;
        position: absolute;
        transform: translate(-32px, 4px);
        cursor: pointer;
        fill: #c2c2c2;

        &:hover {
            fill: $color-b;
        }
    }

    .cloud-share-info__save-link {
        height: 32px;
        position: absolute;
        right: 0;
        transform: translate(-8px, 0);
        line-height: 32px;
    }
}
