cloud-cmig-box {
    display: block;
    text-align: center;

    .cloud-cmig-box {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .cloud-cmig-box__icon {
        width: 32px;
        height: 32px;
        margin: 0 8px;
    }

    .cloud-cmig-box__text {
        display: none;
    }

    @media (min-width: $breakpoint-medium-landscape-min) {
        // override poseidon style because overflow issue;
        .cloud-cmig-box__text {
            display: inline;
        }
    }
}
