.pos-app-layout {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    overflow: hidden;
}

.pos-app-content {
    flex: 1;
}

.pos-app-content,
.pos-app-header,
.pos-app-footer {
    position: relative;
}

.pos-app-header,
.pos-app-footer {
    min-height: 54px;
}
