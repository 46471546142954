cloud-external-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    min-height: auto;
    padding: 0 $cloud-base-padding;
    background-color: $color-c-l30;

    a,
    a:hover {
        color: $white;
    }

    // sass-lint:disable no-vendor-prefixes
    @media (-moz-touch-enabled: 1), (pointer: coarse) {
        a {
            &:hover {
                color: $white;
            }
        }
    }

    .cloud-external-footer__content {
        display: flex;
        align-items: center;
    }

    .cloud-external-footer__content--left {
        flex-wrap: wrap;
    }

    .cloud-external-footer__link {
        padding: 0 10px;
        white-space: nowrap;
    }

    @media (max-width: $breakpoint-extra-small-max) {
        justify-content: center;

        .cloud-external-footer__content--right {
            display: none;
        }

        .cloud-external-footer__link {
            font-size: $cloud-font-size-small;
        }
    }
}
