$dialog-action-buttons-height: 48px;
$dialog-button-space: 8px;

.pos-dialog-action-buttons {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: flex-end;
    height: $dialog-action-buttons-height;
    max-height: $dialog-action-buttons-height;
    padding: $dialog-button-space;
    background-color: $color-c-l94;
    overflow: hidden;
}

.pos-dialog-action-buttons .pos-button + .pos-button {
    margin-left: $dialog-button-space;
}
