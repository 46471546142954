$color-cloud-default-calc: #6ea64e;
$color-cloud-default-exe: #4b4a4a;
$color-cloud-default-file: #8a8a8a;
$color-cloud-default-html: #ad1e24;
$color-cloud-default-image: #4ba5cb;
$color-cloud-default-music: #563b8c;
$color-cloud-default-pdf: #ad1e24;
$color-cloud-default-slide: #d6732c;
$color-cloud-default-text: #427bba;
$color-cloud-default-video: #bc4fa9;
$color-cloud-default-zip: #e7ab30;
$color-cloud-default-office: #ffda00;

.cloud-default-folder         { &.pos-svg-icon { fill: $color-b1; } }
.cloud-default-calc           { &.pos-svg-icon { fill: $color-cloud-default-calc; } }
.cloud-default-exe            { &.pos-svg-icon { fill: $color-cloud-default-exe; } }
.cloud-default-file           { &.pos-svg-icon { fill: $color-cloud-default-file; } }
.cloud-default-files          { &.pos-svg-icon { fill: $color-cloud-default-file; } }
.cloud-default-html           { &.pos-svg-icon { fill: $color-cloud-default-html; } }
.cloud-default-image          { &.pos-svg-icon { fill: $color-cloud-default-image; } }
.cloud-default-music          { &.pos-svg-icon { fill: $color-cloud-default-music; } }
.cloud-default-pdf            { &.pos-svg-icon { fill: $color-cloud-default-pdf; } }
.cloud-default-slide          { &.pos-svg-icon { fill: $color-cloud-default-slide; } }
.cloud-default-text           { &.pos-svg-icon { fill: $color-cloud-default-text; } }
.cloud-default-zip            { &.pos-svg-icon { fill: $color-cloud-default-zip; } }
.cloud-default-office         { &.pos-svg-icon { fill: $color-cloud-default-office; } }
.cloud-default-video          { &.pos-svg-icon { fill: $color-cloud-default-video; } }

.cloud-svg-inverted           { &.pos-svg-icon { fill: $color-d; } }


cloud-file-uploader-input {
    visibility: hidden;
}

// TODO: error in pos-core? (color: #1f6a9f does not exist in pos-core-colors)
a {
    &:hover,
    &:focus,
    &.active {
        color: $color-b1;
    }
}

cloud-app {
    height: 100%;
    visibility: visible;
    user-select: none;

    .cloud-dialog-details-container {
        // TODO:
        max-width: 500px;
    }

    @media (hover: hover) {
        .pos-button--link:hover {
            background-color: $color-b1-l82;
        }
    }
}

.pos-column-layout__column1 {
    height: 100%;
    z-index: $cloud-zindex-sidebar;
}

.pos-column-layout__column1 section {
    height: 100%;
}

/* DnD fixes */
.cloud--dnd-over cloud-app * {
    pointer-events: none;
}

cloud-drag-info * {
    pointer-events: none;
}

.cloud--dnd-over .cloud--dnd-target {
    pointer-events: auto;
}

.cloud--dnd-over .cloud--dnd-target > td {
    pointer-events: auto;
}

.cloud--dnd-over pos-app-layout.cloud--dnd-target > div.pos-app-layout {
    pointer-events: auto;
}

.cloud-requirements-dialog {
    padding-left: 15px;
    text-align: left;
}

.pos-dialog-header__close-button:hover {
    opacity: 1;
    fill: $color-c-l30;
}

.pos-svg-icon--72 {
    width: 72px;
    height: 72px;
}

.pos-svg-icon--64 {
    width: 64px;
    height: 64px;
}

.pos-svg-icon--56 {
    width: 56px;
    height: 56px;
}

.pos-svg-icon--20 {
    width: 20px;
    height: 20px;
}
