.pos-brand-logo {
    display: flex;
    align-items: center;
    white-space: nowrap;
    fill: $color-v;
}

.pos-brand-icon {
    cursor: pointer;
}
