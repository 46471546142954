cloud-placeholder {
    .cloud-placeholder__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .cloud-placeholder__content {
        align-items: center;
        justify-content: center;
        // width: 100%;
        max-width: $breakpoint-extra-small-max;
        padding: $cloud-base-padding;
        color: $color-c-l50;
        font-size: $cloud-font-size-medium;
        text-align: center;

        h1 {
            font-size: $cloud-font-size-large;
        }
    }

    .cloud-placeholder__icon {
        width: 100%;

        .pos-svg-icon {
            height: $cloud-base-400pct;
            fill: $color-c-l30;
        }
    }

    .cloud-placeholder__visual {
        width: 100%;
        height: auto;
    }

    .cloud-placeholder__visual-filters {
        width: 100%;
        max-width: 180px;
        min-height: 180px;
        height: auto;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: auto;
    }

    .cloud-placeholder__visual-container {
        position: relative;
        text-align: center;
    }

    .cloud-placeholder__visual-text {
        font-size: $cloud-base-layout-font;
        color: $color-c;
        padding-top: $cloud-base-padding * 3;
        padding-bottom: $cloud-base-padding * 2;
    }

    .cloud-placeholder__actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        .pos-button {
            margin: $cloud-base-padding;
        }
    }
}