cloud-dialog-mode-footer-buttons {

    .cloud-app-footer__malware-hint {
        display: inline-block;
        padding: $cloud-base-025pct 0;
        margin-right: $cloud-base-200pct;
        line-height: $cloud-base-150pct;
        vertical-align: top;
    }

    .cloud-app-footer__infected-icon {
        margin-right: $cloud-base-025pct;
        vertical-align: top;

        svg {
            fill: $color-h1;
        }
    }
}
