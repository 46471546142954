$white: #fff;
$black: #000;

$color-a-l15: mix($white, $color-a, 15%);
$color-a-l30: mix($white, $color-a, 30%);
$color-a-l40: mix($white, $color-a, 40%);
$color-a-l50: mix($white, $color-a, 50%);
$color-a-l70: mix($white, $color-a, 70%);
$color-a-l82: mix($white, $color-a, 82%);
$color-a-l94: mix($white, $color-a, 94%);
$color-a-d10: mix($black, $color-a, 10%);
$color-a-d20: mix($black, $color-a, 20%);

$color-b-l15: mix($white, $color-b, 15%);
$color-b-l30: mix($white, $color-b, 30%);
$color-b-l40: mix($white, $color-b, 40%);
$color-b-l50: mix($white, $color-b, 50%);
$color-b-l70: mix($white, $color-b, 70%);
$color-b-l82: mix($white, $color-b, 82%);
$color-b-l94: mix($white, $color-b, 94%);
$color-b-d10: mix($black, $color-b, 10%);
$color-b-d20: mix($black, $color-b, 20%);

$color-b1-l15: mix($white, $color-b1, 15%);
$color-b1-l30: mix($white, $color-b1, 30%);
$color-b1-l40: mix($white, $color-b1, 40%);
$color-b1-l50: mix($white, $color-b1, 50%);
$color-b1-l70: mix($white, $color-b1, 70%);
$color-b1-l82: mix($white, $color-b1, 82%);
$color-b1-l94: mix($white, $color-b1, 94%);
$color-b1-d10: mix($black, $color-b1, 10%);
$color-b1-d20: mix($black, $color-b1, 20%);

$color-b2-l15: mix($white, $color-b2, 15%);
$color-b2-l30: mix($white, $color-b2, 30%);
$color-b2-l40: mix($white, $color-b2, 40%);
$color-b2-l50: mix($white, $color-b2, 50%);
$color-b2-l70: mix($white, $color-b2, 70%);
$color-b2-l82: mix($white, $color-b2, 82%);
$color-b2-l94: mix($white, $color-b2, 94%);
$color-b2-d10: mix($black, $color-b2, 10%);
$color-b2-d20: mix($black, $color-b2, 20%);

$color-c-l15: mix($white, $color-c, 15%);
$color-c-l30: mix($white, $color-c, 30%);
$color-c-l40: mix($white, $color-c, 40%);
$color-c-l50: mix($white, $color-c, 50%);
$color-c-l70: mix($white, $color-c, 70%);
$color-c-l82: mix($white, $color-c, 82%);
$color-c-l94: mix($white, $color-c, 94%);
$color-c-d10: mix($black, $color-c, 10%);
$color-c-d20: mix($black, $color-c, 20%);
