.pos-spinner {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // Edge need also the width otherwise the icon is not rendered
    width: 30px;
    height: 30px;
}

.pos-spinner__bar {
    position: absolute;
    top: 0;
    width: 2px;
    height: 8px;
    transform-origin: 1px 15px;
    background-color: $color-c;
    opacity: 0.05;
    animation: fadeit 0.8s linear infinite;
}

@keyframes fadeit {
    0% { opacity: 1; }
    100% { opacity: 0;}
}

$i: 16;
@while $i > 0 {
    $i: $i - 1;

    .pos-spinner__bar--bar#{$i} {
        transform: rotate($i * 22.5deg);
        animation-delay: $i * 0.05s;
    }
}
