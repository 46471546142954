cloud-standalone-header {

    &.pos-app-header__section {
        display: flex;
        height: $cloud-big-header-height;
        border-top: 4px solid $color-a;
        background-color: $color-o;
        color: $color-v;

        .pos-brand-logo {
            margin-left: 16px;
        }

        cloud-register-box {
            flex-grow: 1;
            flex-shrink: 0;
        }
    }

    @media (max-width: $breakpoint-extra-small-max) {
        &.pos-app-header__section .pos-brand-logo {
            margin-left: 0;
        }
    }

    @media (max-width: $breakpoint-extra-small-max) {
        &.pos-app-header__section {
            justify-content: center;
            height: $cloud-small-header-height;

            .pos-brand-title {
                display: none;
            }
        }
    }
}
