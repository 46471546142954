cloud-app-navigator {
    width: 100%;
    height: 100%;

    .cloud-app-navigator__button-container {
        display: inline-flex;
        width: 44px;
        height: 100%;
        margin-left: 8px;
        padding: 4px 10px;

        pos-svg-icon {
            cursor: pointer;
            fill: $color-t;
        }
    }

    .cloud-app-navigator__button-container--active {
        background-color: $color-c-l94;

        pos-svg-icon {
            cursor: initial;
            fill: $color-b1;
        }
    }

    .cloud-app-navigator__button {
        margin-top: 6px;
    }
}
