cloud-sidebar-links {
    a,
    a:focus,
    a.active {
        padding: $cloud-base-padding 0;
        color: $color-b;
        text-decoration: none;
    }

    .pos-svg-icon {
        fill: $color-b;
    }

    .pos-icon-item__label {
        margin-left: $cloud-base-padding;
    }

    .cloud-sidebar-link--empty {
        height: $cloud-sidebarlink-height;
    }

    a:hover {
        text-decoration: underline;
    }

    .cloud-sidebar-link--icons-only {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;

        a {
            svg {
                fill: $color-c-l40;
            }

            &:active,
            &:hover {
                svg {
                    fill: $color-b;
                }
            }
        }

        .cloud-sidebar-link--divider {
            margin: 0 6px;
            border-left: 1px $color-c-l82 solid;
            height: 16px;
        }
    }

    .cloud-sidebar-link--app {
        display: inline-block;
    }
}

