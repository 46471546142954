cloud-register-box {
    height: 100%;

    .cloud-register-box {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: flex-end;
        height: 100%;
        margin-right: $cloud-base-padding;

        .cloud-register-box__text {
            padding-right: $cloud-base-padding;
            text-align: right;
        }
    }
}
