cloud-share-list {
    display: flex;
    flex-direction: column;

    cloud-tool-bar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    cloud-scroll-area {
        flex-grow: 1;
    }

    .cloud-share-list-component__empty {
        padding-right: $cloud-base-padding;
        padding-left: $cloud-base-padding;
        text-align: center;
    }

    .pos-table__cell--date {
        flex-basis: 112px;
        text-align: center;
    }
}
