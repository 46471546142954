cloud-detail-view {
    $cloud-detail-buttons: rgba($black, 0.5);
    $cloud-detail-icon-size: $cloud-base-200pct;
    $cloud-detail-arrow-height: $cloud-base-300pct * 4;

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $color-c-d10;
    overflow: hidden;
    z-index: 0; /* due to dialog issues */

    .cloud-detail__action-bar-container {
        position: absolute;
        width: 100%;
        border-bottom: 0;
        background-color: $color-c-l15;
        z-index: $cloud-zindex-detail-bar;
    }

    .swipe-container {
        width: 100%;
        height: 100%;

        pos-spinner {
            margin: auto;
        }
    }


    .navigation-container {
        display: table;
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;

        pos-svg-icon { // set fill & size for pos-icon-item
            width: $cloud-detail-icon-size;
            height: $cloud-detail-icon-size;
            fill: $color-d;
        }
    }

    .navigation {
        display: table-cell;
        width: 100%;
        vertical-align: middle;

        button {
            position: absolute;
            top: calc(50% - 192px * 0.5); // $cloud-detail-arrow-height cant mix em and % ...

            height: $cloud-detail-arrow-height;
            border: 0;
            background-color: $cloud-detail-buttons;

            z-index: $cloud-zindex-detail-navi;

            @media only screen and (max-width: 600px) {
                display: none;
            }

            &.pull-right {
                right: 0;
                margin: $cloud-base-padding;
                padding: $cloud-base-padding;
            }

            &.pull-left {
                margin: $cloud-base-padding;
                padding: $cloud-base-padding;
            }

            &.pull-left--metadata-blended-in {
                margin: $cloud-base-padding;
                margin-left: $cloud-meta-info-width + 8px;
                padding: $cloud-base-padding;
            }

        }
    }

    .core-close {
        position: absolute;
        top: $cloud-base-padding;
        right: $cloud-base-padding;
        border: 0;
        background-color: transparent;
    }

    .cloud__component--hidden {
        visibility: hidden;
    }

}
