cloud-info-tour {
    .cloud-info-tour__slide {
        padding: 0 15px;

        .cloud-info-tour__slide-headline,
        .cloud-info-tour__slide-subheadline {
            display: block;
            color: $color-b;
            font-weight: normal;
            text-align: left;
        }

        .cloud-info-tour__slide-headline {
            margin: 2px 0 8px;
        }

        .cloud-info-tour__slide-image {
            height: 370px;
            margin: 0 50px;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
        }

        .cloud-info-tour__slide-text {
            text-align: left;
        }

        .cloud-info-tour__slide-text--strong {
            font-weight: bold;
        }

        .cloud-info-tour__slide-link {
            color: $color-b;
            text-decoration: underline;

            &:hover,
            &:active {
                text-decoration: none;
                cursor: pointer;
            }
        }

        .cloud-info-tour__slide-text--small-space {
            letter-spacing: -0.2px;
        }

        .cloud-info-tour__slide-text--center {
            text-align: center;
        }

        .cloud-info-tour__slide-btn-info-tour {
            display: block;
            margin: 10px auto;
        }
    }
    @media (max-width: $breakpoint-medium-landscape-min) {
        .cloud-info-tour__slide {
            padding: 0;

            .cloud-info-tour__slide-image {
                height: 170px;
                margin-bottom: 10px;
            }
        }
    }
}
