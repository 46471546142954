cloud-meta-information {
    position: absolute;
    top: 0;
    left: 0;
    width: $cloud-meta-info-width;
    height: 100%;
    padding: $cloud-action-bar-height + $cloud-base-100pct $cloud-base-100pct $cloud-base-100pct $cloud-base-100pct;
    background: $cloud-meta-info-background;
    color: $white;
    cursor: default;
    z-index: $cloud-zindex-detail-meta;
    overflow-y: auto;
    user-select: text;
    word-wrap: break-word;

    .cloud-meta-information-headline {
        font-weight: bold;
    }

    .pos-icon-item__label {
        padding-left: $cloud-base-padding * 2;
    }

    .cloud-meta-information-items {
        padding: 0 0 $cloud-base-padding 24px;
        font-size: $cloud-font-size-small;

        div {
            padding-top: $cloud-base-padding;
            padding-left: $cloud-base-padding * 2;
        }
        div a { color: $white; }
    }

    .cloud-meta-information-arrangement {
        padding-bottom: $cloud-base-padding * 4;
    }

    .cloud-meta-information-ellipsis {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .pos-svg-icon {
        width: 24px;
        height: 24px;
        fill: $color-d;
    }

    .cloud-meta-information-close {
        right: $cloud-base-padding;
        top: $cloud-base-padding * 7;
        position: absolute;
    }

    .cloud-meta-information-close:hover {
        background-color: $color-c-l15;
    }


    table {
        width: calc(100% - #{$cloud-base-padding} * 2);
        margin-right: $cloud-base-padding * 2;
        margin-left: $cloud-base-padding * 2;
        overflow: hidden;
        table-layout: fixed;

        td {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .cloud-meta-information-table-label {
        width: 40%;
        padding-right: $cloud-base-padding;
    }

    td {
        padding-top: $cloud-base-padding;
    }

    .cloud-meta-information-malware {
        display: flex;

        .cloud-meta-information-items {
            padding-left: 2 * $cloud-base-padding;
        }
    }
}
