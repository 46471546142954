cloud-last-edited {
    display: flex;
    flex-direction: column;

    cloud-tool-bar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    cloud-scroll-area {
        flex-grow: 1;
    }

    .cloud-last-edited__spinner--bottom {
        margin: auto;
        margin-top: -30px;
    }
}
