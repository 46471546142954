cloud-list {
    display: block;

    .pos-table {
        margin-bottom: 0;
    }

    .pos-table__head {
        display: flex;
        align-items: center;
        height: $cloud-base-400pct;
        background-color: $white;
        position: sticky;
        top: 0;
        z-index: 1;

        .pos-table--content-right {
            color: inherit;
        }

        .pos-table__cell {
            font-size: $cloud-font-size-small;
            font-weight: normal;
        }

        .pos-table__cell--name {
            font-weight: bold;
            flex-basis: 200px;
        }

        & > * {
            margin-top: $cloud-base-200pct;
        }
    }

    .pos-table__body {
        position: relative;

        .pos-table__row--active:not(:hover) .pos-table__cell--malware .pos-svg {
            fill: $color-h1;
        }
    }

    .pos-table__row {
        display: flex;
        align-items: center;
        height: $cloud-base-275pct;
    }

    .pos-table__row,
    .cloud-tile-item--expired {
        &.pos-table__row--expired,
        &.pos-table__row--disabled,
        .cloud-tile-item__selector-inner--folder {
            color: $color-c-l50;
        }
    }

    // sass-lint:disable no-vendor-prefixes
    @media (hover: hover), (-ms-high-contrast: none) {
        // TODO: move to Core
        .pos-table__body .pos-table__row:hover {
            background-color: $color-b1-l94;
        }

        // TODO: move to Core
        .pos-table__body .pos-table__row--active:hover .pos-table__cell {
            color: $color-c;
        }

        // TODO: move to Core
        .pos-table__body .pos-table__row--active:hover .pos-table__cell--content-light {
            color: $color-c-l50;
        }

    }

    .pos-table__row--expired .pos-svg-icon,
    .cloud-tile-item--expired .cloud-tile-item__type .pos-svg-icon {
        fill: $color-c-l50;
    }

    .pos-table__row--expired .cloud-list-item__content--visual-media,
    .cloud-tile-item--expired .cloud-tile-item__content--visual-media {
        filter: grayscale(100%);
    }

    // sass-lint:disable no-vendor-prefixes
    @media (hover: hover) {
        .pos-table__row--expired .cloud-list-item__content--visual-media:hover,
        .cloud-tile-item--expired .cloud-tile-item__content--visual-media:hover {
            filter: unset;
        }
    }

    .pos-table__cell {
        flex-grow: 0;
        flex-shrink: 0;
        padding-right: $cloud-base-padding;
        line-height: unset;

        &:first-child {
            padding-left: 6px;
        }

        &.pos-table__cell--content-light {
            padding-right: 14px;
            color: $color-c-l50;
            font-size: $cloud-font-size-small;
            white-space: nowrap;
        }

        &.cloud-list-item__share-icon {
            padding-right: 0;
        }

        &.pos-table__cell--content-link {
            color: $color-d;
        }

        &.pos-table__cell--date {
            padding-right: 12px;
        }

        &.pos-table__cell--malware {
            padding-right: 10px;
        }
    }

    .pos-table__cell--checkbox {
        flex-basis: 52px;
        text-overflow: clip;

        .pos-input-checkbox {
            padding-left: $cloud-base-padding;
        }
    }

    .pos-table__cell--preview {
        flex-basis: 40px;
        height: 100%;

        .cloud-list-item__content--visual-media {
            position: absolute;
            width: $cloud-base-200pct;
            height: $cloud-base-200pct;
            margin-top: 6px;
            background-position: center;
            background-size: cover;
        }

        @media (-moz-touch-enabled: 0), (pointer: fine) {
            &:hover {
                position: relative;
                overflow: visible;

                .cloud-list-item__content--visual-media {
                    position: absolute;
                    top: -10px;
                    left: -$cloud-base-200pct * 0.5;
                    width: $cloud-base-200pct * 2;
                    height: $cloud-base-200pct * 2;
                    margin: 0;
                    z-index: 1;
                }
            }
        }
    }

    .pos-table__cell--icon {
        flex-basis: 40px;

        .pos-svg-icon {
            svg {
                width: $cloud-base-200pct;
                height: $cloud-base-200pct;
            }
        }
    }

    .pos-table__cell--name {
        flex-basis: 140px;
        flex-grow: 1;
        flex-shrink: 3;
    }

    .pos-table__cell--shares {
        display: flex;
        justify-content: center;
        flex-basis: 80px;
    }

    @media (max-width: $breakpoint-extra-small-max) {
        .pos-table__cell--shares {
            flex-basis: 35px;
            justify-content: flex-end;
        }
    }

    .pos-table__cell--date {
        flex-basis: 88px;
    }

    .pos-table__cell--size {
        flex-basis: 80px;
    }

    .pos-table__cell--parent {
        flex-basis: 200px;
        flex-shrink: 4;
    }

    cloud-list-item,
    cloud-grid-item {
        position: absolute;
    }

    .pos-table__selector {
        display: flex;
        position: fixed;
        right: 17px;
        flex-direction: column;
        margin-top: 8px;
        z-index: $cloud-zindex-detail-navi;

        cloud-list-fast-scroll {
            flex-grow: 1;
        }
    }

}
