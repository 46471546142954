.u-fade-in {
    opacity: 1;
    animation: fadein 0.5s;
}

.u-fade-out {
    opacity: 0;
    animation: fadeout 0.5s;
}

@keyframes fadein {
    from {opacity: 0}
    to {opacity: 1}
}

@keyframes fadeout {
    from {opacity: 1}
    to {opacity: 0}
}
