// CSS image replacement
//
// Heads up! v3 launched with only `.hide-text()`, but per our pattern for
// mixins being reused as classes with the same name, this doesn't hold up. As
// of v3.0.1 we have added `.text-hide()` and deprecated `.hide-text()`.
//
// Source: https://github.com/h5bp/html5-boilerplate/commit/aa0396eae757

@mixin text-hide {
    border: 0;
    background-color: transparent;
    color: transparent;
    font: 0 / 0 a;
    text-shadow: none;
}
