$scroll-line-width: 100px;
$maximum-width: 300px;

cloud-list-fast-scroll {
    display: flex;
    flex-direction: column;
    cursor: row-resize;
}

.cloud-list-fast-scroll {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: $scroll-line-width;
    transform: translate($maximum-width);
    transition: transform 0.2s;

    &.cloud-list-fast-scroll--visible {
        transform: translate(0);
    }

    .cloud-list-fast-scroll__area {
        display: flex;
        position: relative;
        flex-direction: column;

        &:last-child .cloud-list-fast-scroll__label--area {
            top: auto;
            bottom: 0;
            order: 3;
        }
    }

    .cloud-list-fast-scroll__label--area {
        position: absolute;
        top: 0;
        left: 28px;
        z-index: -1;
    }

    .cloud-list-fast-scroll__month-group {
        position: relative;
        flex-basis: 1px;
        flex-shrink: 0;
        order: 2;
    }
}

.cloud-list-fast-scroll__label {
    flex-basis: 0;
    flex-grow: 0;
    order: 1;
    min-width: 48px;
    margin: 0 auto;
    padding: 2px 5px;
    border-radius: $border-radius-base;
    background-color: $color-c-l94;
    font-size: $cloud-font-size-small;
    text-align: center;
    white-space: nowrap;

    &.cloud-list-fast-scroll__label--marker {
        flex-basis: auto;
        margin: 0 12px 0 0;
    }
}

.cloud-list-fast-scroll__marker {
    display: flex;
    position: absolute;
    top: -50%;
    align-items: center;
    width: $maximum-width;
    height: 0;
    margin-left: -($maximum-width - $scroll-line-width);
    transition: top 0.3s;
    justify-items: right;

    & > * {
        flex-grow: 0;
        flex-shrink: 0;
    }

    &.cloud-list-fast-scroll__marker--draggable {
        height: 0;
        transition: unset;

        .cloud-list-fast-scroll__line {
            background-color: $color-c-l50;
        }
    }

    .cloud-list-fast-scroll__line {
        order: 2;
        width: $scroll-line-width;
        height: 2px;
        background-color: $color-b;
    }

    .cloud-list-fast-scroll__space {
        flex-grow: 1;
        order: 0;
    }
}

