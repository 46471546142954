@import 'pos-message-vars';

.pos-message {
    width: 100%;
    margin-bottom: 16px;

    @include media-query(medium-large) {
        width: $message-max-width;
    }
}

.pos-message__wrapper {
    width: inherit;
    padding: 16px;
    border: 1px solid $color-c-l50;
    background-color: $color-d;

    @include media-query(small) {
        border-width: 1px 0;
    }

    @include media-query(medium-large) {
        border-radius: $border-radius-base;
    }
}

.pos-message__heading {
    font-size: 18px;
    line-height: 24px;
}

.pos-message__details {
    flex-basis: auto;
    margin-top: 8px;
}

.pos-message__details:empty {
    display: none;
}


.pos-message__action-link {
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;
}


.pos-message__close-button {
    width: 32px;
    height: 32px;
    margin: (-4px) (-4px) (-8px) 12px;
}


.pos-message__icon {
    margin-right: 12px;
}


.pos-message__icon--info {
    fill: $message-info-color;
}

.pos-message__icon--success {
    fill: $message-success-color;
}

.pos-message__icon--warning {
    fill: $message-warning-color;
}

/****************************
 DEPRECATED
 ************************* */
.pos-message__icon--failure {
    fill: $message-failure-color;
}

.pos-message__icon--error {
    fill: $message-error-color;
}


.pos-message__wrapper--info {
    border-bottom: 5px solid $message-info-color;
}

.pos-message__wrapper--success {
    border-bottom: 5px solid $message-success-color;
}

.pos-message__wrapper--warning {
    border-bottom: 5px solid $message-warning-color;
}

/****************************
 DEPRECATED
 ************************* */
.pos-message__wrapper--failure {
    border-bottom: 5px solid $message-failure-color;
}

.pos-message__wrapper--error {
    border-bottom: 5px solid $message-error-color;
}


.pos-message--floating {
    position: absolute;
    top: 0;
    z-index: 99;

    @include media-query(medium-large) {
        left: 50%;
        margin-left: -$message-max-width * 0.5;
    }
}
