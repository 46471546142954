cloud-hamburger {
    padding: 8px 0;
    background-color: $color-c-l94;

    .cloud-hamburger {
        display: flex;
        align-items: center;
    }
}

@media (max-width: $breakpoint-extra-small-max) {
    cloud-hamburger {
        border-top: 0;
        border-bottom: 0;
    }
}
