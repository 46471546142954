$dialog-header-title-font-height: 24px;
$dialog-header-close-button-size: 48px;

.pos-dialog-header {
    display: flex;
    flex-shrink: 0;
    justify-content: flex-end;
    min-height: 48px;
}

.pos-dialog-header--separated {
    margin-bottom: 24px;
    border-bottom: 1px solid $color-c-l82;
}

.pos-dialog-header__title {
    flex-grow: 1;
    max-height: 2 * $dialog-header-title-font-height;
    margin: 12px 0 12px 16px;
    color: $color-c;
    font-size: 16px;
    line-height: $dialog-header-title-font-height;
    overflow: hidden;
}

.pos-dialog-header__close-button {
    flex-shrink: 0;
    width: $dialog-header-close-button-size;
    height: $dialog-header-close-button-size;
    padding: 0;
    border: 0;
    background: transparent;
    fill: $color-c-l50;
    appearance: none;

    &:hover,
    &:focus {
        opacity: 0.5;
    }
}
