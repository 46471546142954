cloud-storage-bar {
    cursor: pointer;

    pos-form-message {
        cursor: pointer;

        .pos-form-message__wrapper {
            margin-bottom: 0;
        }
    }

    pos-progressbar {
        cursor: pointer;
        margin-top: 0;
    }

    .cloud-storage-bar--extend-link {
        margin-left: $poseidon-svg-dimension + $poseidon-button-vertical-padding;
    }

    a,
    a:hover,
    a:focus,
    a.active {
        color: $color-link;
        text-decoration: none;
        font-size: $cloud-font-size-small;
    }

    .pos-svg-icon {
        fill: $color-b;
    }

    .cloud-storage-info-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        line-height: 14px;
        font-size: 10px;
    }

    .cloud-storage-text,
    .cloud-storage-percentage {
        color: $color-c-l30;
    }

    .cloud-function-icon .pos-icon-item__label {
        margin-left: 8px;
    }

    hr {
        flex-basis: 100%;
        margin: 0;
        border-top: 1px $color-c-l82 solid;
    }

    hr:first-child {
        margin-bottom: $cloud-base-padding;
    }

    hr:last-child {
        margin-top: $cloud-base-padding;
    }

    .cloud-storage__upgrade-container {
        align-items: center;
        padding-top: $cloud-base-padding;
        cursor: initial;

        a:hover {
            text-decoration: underline;
        }
    }

    .cloud-storage__badge {
        font-size: $cloud-font-size-small;
        line-height: 16px;
        border-radius: 2px;
        background-color: $color-a;
        color: $color-f;
        padding: 0 $cloud-base-padding * 0.5;
        margin-left: $cloud-base-padding;
        text-transform: uppercase;
    }
}
