/* posdoc
  name: Flexbox Classes
  description: 'Helper classes to provide users with an easier way to interact with the flexbox layout system.
  <a href="https://css-tricks.com/snippets/css/a-guide-to-flexbox/"> More information on flexbox</a>'
  data:
*/

/* posdoc
    pos-scss/objects/flex.scss:
        '<code>
            <b>.l-inline</b><br>
            Use this class to define a inline flex container which enables
            a flex content for all direct children<br>
            display: inline-flex;<br/><br/>

            <b>.l-horizontal</b><br>
            This class defines a horizontal layout for all children of the flex container<br>
             flex-direction: row;<br><br>

             <b>.l-horizontal.l-reverse</b><br>
             This class defines a reverse horizontal layout (right to left) for all children of the flex container<br>
             flex-direction: row-reverse;<br><br>

             <b>.l-vertical</b><br>
             This class defines a vertical layout for all direct children of the flex container<br>
             flex-direction: column;<br><br>

             <b>.l-vertical.l-reverse</b><br>
             This class defines a reverse vertical layout (bottom to top) for all direct of the flex container<br>
             flex-direction: column-reverse;<br/><br/>

             <b>.l-wrap</b><br>
             Child elements will be flowed into multiple lines in the direction defined by the
             flex-direction property (they will not be forced into a single line)<br>
             flex-wrap: wrap;<br/><br/>

             <b>.l-nowrap</b><br>
             Child elements will not be flowed into multiple lines<br>
             flex-wrap: nowrap;<br/><br/>

             <b> .l-wrap-reverse</b><br>
             Child elements will be flowed into multiple lines in the opposite
             direction defined by the flex-direction property (they will not be forced into a single line)<br>
             flex-wrap: wrap-reverse;<br/><br/>

             <b>.l-flex</b><br>
             Child elements with this class will split the remaining space in the container equally.
             If there is only one element with this class it will take up all the remaining space<br>
             flex: 1;<br/><br/>

             <b>.l-flex-auto</b><br>
             Child elements with this class will split the remaining space in the container equally.
             If there is only one element with this class it will take up all the remaining space.<br>
             flex: 1 1 auto;<br/><br/>

             <b>.l-flex-none</b><br>
             Child elements with this class will not grow to occupy remaining space or shrink to
             accomodate if there are multiple elements that would not fit in the parent<br>
             flex: none;<br/><br/>

             <b>.l-grow: flex-grow: 1;</b><br>
             Child elements with this class will split the remaining space in the container equally.
             If there is only one element with this class it will take up all the remaining space<br>
             flex-grow: 1;<br><br>

             <b>.l-flex-[0-12]</b><br>
             Flex item receives an amount of space inside the flex container
             using this formula: (space/sum of all indexes) * index. So for example if the available
             space is 600px and the only elements have .l-flex classes from 1-12 (one each) the formula for .l-flex-6
             is (600/(1+2+...+12)) * 6.<br>
             flex: 0<br>
             flex: 1<br>
             flex: 2<br>
             flex: 3<br>
             flex: 4<br>
             flex: 5<br>
             flex: 6<br>
             flex: 7<br>
             flex: 8<br>
             flex: 9<br>
             flex: 10<br>
             flex: 11<br>
             flex: 12<br><br><br>

             <b> .l-start-aligned</b><br>
             Aligns flex items to the beginning of the flex container
             depending on the flex-direction. If flex-direction is row, items will be
             aligned to the left, if flex direction is column, items will be aligned to
             the top.<br>
             align-items: flex-start;<br/><br/>

             <b>.l-center-aligned</b><br>
             Aligns flex items to the center of the flex
             container based on the main axis (the one defined by flex-direction).<br>
             align-items: center;<br><br>

             <b>.l-end-aligned</b><br>
             Aligns flex items to the end of the flex container
             depending on the flex-direction. If flex-direction is row, items will be
             aligned to the right, if flex direction is column, items will be aligned to
             the bottom.<br>
             align-items: flex-end;<br><br>

             <b>.l-stretch-aligned</b><br>
             Stretches the elements in the direction defined by flex-direction.
             If flex-direction is row the flex items will be stretched vertically to fit the flex
             container, if the flex-direction is column, items will be stretched horizontally to fit
             the flex container.<br>
             align-items: stretch;<br><br>

             <b>.l-start-justified</b><br>
             Aligns flex items to the beginning of the flex container
             depending on the flex-direction. If flex-direction is row, items will be
             aligned to the top, if flex direction is column, items will be aligned to
             the left.<br>
             justify-content: flex-start;<br><br>

             <b>.l-center-justified</b><br>
             Aligns flex items to the center of the flex
             container based on the secondary axis (the one perpendicular to the one
             defined by flex-direction).<br>
             justify-content: center;<br><br>

             <b>.l-end-justified</b><br>
             Aligns flex items to the end of the flex container
             depending on the flex-direction. If flex-direction is row, items will be
             aligned to the bottom, if flex direction is column, items will be aligned to
             the right.<br>
             justify-content: flex-end;<br><br>

             <b>.l-around-justified</b><br>
             Flex items withing the container are evenly distributed
             in the line with equal space around them, more precisely, the extra space on the line
             is distributed into [number of items] * 2 equal units and each item has a `margin`
             around it on the axis perpendicular to the direction defined by flex-direction.<br>
             justify-content: space-around;<br><br>

             <b>.l-between-justified</b><br>
             Flex items within a line are arranged to
             have even space between them. All the extra space is divided into
             [numer of items - 1] parts and distributed between items.<br>
             justify-content: space-between;<br><br>

             <b> .l-self-start-aligned</b><br>
             Overrides the align-items property of the flex container
             for the current item.<br>
             align-self: flex-start;<br><br>

             <b> .l-self-center-aligned</b><br>
             Overrides the align-items property of the flex container
             for the current item.<br>
             align-self: center;<br><br>

             <b>.l-self-end-aligned</b><br>
             Overrides the align-items property of the flex container
             for the current item.<br>
             align-self: flex-end;<br><br>

             <b>.l-self-stretch-aligned</b><br>
             Overrides the align-items property of the flex container
             for the current item.<br>
             align-self: stretch;<br><br>

             <b>.l-fit</b><br>
             Expands the element to fill up its closest positioned ancestor.<br>
             position: absolute;<br>
             top: 0;<br>
             right: 0;<br>
             bottom: 0;<br>
             left: 0;<br><br>

             <b> .l-relative</b><br>
             Set position: relative.<br>
             position: relative;<br><br>
        </code>'
*/

.l-horizontal,
.l-vertical {
    display: flex;
}

.l-inline {
    display: inline-flex;
}

.l-horizontal {
    flex-direction: row;
}

.l-horizontal.l-reverse {
    flex-direction: row-reverse;
}

.l-vertical {
    flex-direction: column;
}

.l-vertical.l-reverse {
    flex-direction: column-reverse;
}

.l-wrap {
    flex-wrap: wrap;
}

.l-nowrap {
    flex-wrap: nowrap;
}

.l-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.l-flex {
    flex: 1;
}

.l-flex-auto {
    flex: 1 1 auto;
}

.l-flex-none {
    flex: none;
}

.l-grow {
    flex-grow: 1;
}

$i: 13;
@while $i > 0 {
    $i: $i - 1;
    .l-flex-#{$i} { flex: $i; }
}

.l-start-aligned {
    align-items: flex-start;
}

.l-center-aligned {
    align-items: center;
}

.l-end-aligned {
    align-items: flex-end;
}

.l-stretch-aligned {
    align-items: stretch;
}

.l-start-justified {
    justify-content: flex-start;
}

.l-center-justified {
    justify-content: center;
}

.l-end-justified {
    justify-content: flex-end;
}

.l-around-justified {
    justify-content: space-around;
}

.l-between-justified {
    justify-content: space-between;
}

.l-self-start-aligned {
    align-self: flex-start;
}

.l-self-center-aligned {
    align-self: center;
}

.l-self-end-aligned {
    align-self: flex-end;
}

.l-self-stretch-aligned {
    align-self: stretch;
}

.l-fit {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.l-relative {
    position: relative;
}
