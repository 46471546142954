cloud-filter-date {
    .pos-button {
        width: 88px;
        justify-content: center;
    }

    cloud-popup .cloud-popup__list--menu {
        width: 88px;
    }
}
