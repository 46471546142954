cloud-media-view {
    display: flex;
    width: 100%;
    height: 100%;

    .cloud-media-view__player {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: $cloud-zindex-detail-video;

        &.cloud-media-view__player--audio {
            object-fit: none;
        }
    }

    .pull-right {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: $cloud-zindex-detail-navi + 1;
    }

    .cloud-media-view__error {
        display: none;
        padding: 0 $poseidon-button-width + 10;
        color: $white;
        text-align: center;
        align-self: center;
        flex-grow: 1;

        &.cloud-media-view__error--visible {
            display: block;
        }

        .cloud-media-view__failure-icon {
            width: $cloud-base-800pct;
            height: $cloud-base-800pct;
            fill: $color-d;
        }
    }

    .cloud-media-view__warning {
        display: none;
        position: absolute;
        top: 0;
        width: 100%;
        margin-top: $cloud-action-bar-height;
        text-align: center;
        z-index: $cloud-zindex-detail-video;

        .cloud-media-view__warning-message {
            display: inline-block;
            max-width: 300px;
            padding: $poseidon-button-width * 0.5;
            border-radius: 0 0 $border-radius-base $border-radius-base;
            background-color: $cloud-shadow;
            color: $white;
        }

        &.cloud-media-view__warning--visible {
            display: block;
        }
    }

    @media (max-height: 400px) {
        .cloud-media-view__warning {
            top: calc(100vh - 140px);

            .cloud-media-view__warning-message {
                max-width: none;
                padding: $poseidon-button-width * 0.25;
                white-space: nowrap;
            }
        }
    }
}

