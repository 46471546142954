$message-min-width: 320px;
$message-max-width: 480px;

$message-info-color: $color-h4;
$message-success-color: $color-h3;
$message-warning-color: $color-h1;
$message-error-color: $color-h2;

/****************************
 DEPRECATED
 ************************* */
$message-failure-color: $color-h2;
