// Base styles
// -------------------------
.pos-button {
    display: inline-flex;
    margin-bottom: 0; // For input.pos-button
    border: 0;
    background-color: transparent;
    background-image: none; // Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214
    font-weight: normal;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: middle;
    touch-action: manipulation;

    @include button-size(4px, 12px, $font-size-base, 24px, $border-radius-base);
    @include user-select(none);
}

// TODO move to .pos-button as with the removal of the deprecation
[pos-button].pos-button {
    padding: 4px 12px;
    border: 0 solid transparent;
}

.pos-button.active,
.pos-button:active,
.pos-button:hover,
.pos-button:focus,
.pos-button.focus {
    outline: 0;
}

.pos-button[disabled],
.pos-button.disabled {
    cursor: not-allowed;
    @include opacity(0.65);
}

// hover styles

@media (-moz-touch-enabled: 1), (pointer: coarse) {
    .pos-button:hover {
        outline: unset;
    }
}

// CTA buttons
// -------------------------
.pos-button--cta {
    @include button-variant($color-f, $color-a, transparent);
    @include button-selected($color-f, $color-a, transparent);
}

// Primary  button
// -------------------------
.pos-button--primary {
    @include button-variant($color-d, $color-b1, $color-b1);
    @include button-selected($color-d, $color-b1, $color-b1);
}

// Tertiary buttons
// -------------------------
.pos-button--tertiary {
    @include button-variant($color-c-d20, $color-c-l82, $color-d, $color-c-l50);
    @include button-selected($color-d, $color-b1, $color-b1);
}

// Link buttons
// -------------------------
.pos-button--link {
    transition: background-color 0.2s ease;

    @include button-variant($color-b1, transparent, transparent, $color-b1-d10, $color-d, $color-b1-l82);
    @include button-selected($color-d, $color-b1, $color-b1);

    &[disabled] {
        color: $color-c-l50;
    }
}

// Support for icons
// -------------------------
// TODO remove attribute selector on remove of the deprecated code
[pos-button].pos-button .pos-svg-icon {
    width: 24px;
    height: 24px;
    margin-right: -4px;
    margin-left: -4px;
}

// TODO remove attribute selector on remove of the deprecated code
[pos-button].pos-button .pos-svg-icon .pos-svg {
    padding: 2px;
}

.pos-button.pos-button--icon-left .pos-svg-icon {
    margin-right: 12px;
}

.pos-button.pos-button--icon-right .pos-svg-icon {
    margin-left: 12px;
}

/****************************
 DEPRECATED
 ************************* */

.pos-button__button {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 6px 12px;
    border: 0;
    background: transparent;
    line-height: 24px;
}

.pos-button__button[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

// Button Sizes. Placeholder are used later.
// --------------------------------------------------

.pos-button--lg,
%pos-button--lg {
    // line-height: ensure even-numbered height of button next to large input
    @include button-size(0, 0, $font-size-large, $line-height-large, $border-radius-large);
}

.pos-button--lg .pos-button__button {
    padding: 10px 16px;
}

.pos-button--sm,
%pos-button--sm {
    // line-height: ensure proper height of button next to small input
    @include button-size(0, 0, $font-size-small, $line-height-small, $border-radius-small);
}

.pos-button--sm .pos-button__button {
    padding: 5px 10px;
}

.pos-button--xs,
%pos-button--xs {
    @include button-size(0, 0, $font-size-small, $line-height-small, $border-radius-small);
}

.pos-button--xs .pos-button__button {
    padding: 1px 5px;
}

.pos-button--cta.pos-button--lg {
    font-size: 14px;
    font-weight: normal;
}

.pos-button--cta.pos-button--lg .pos-button__button {
    padding: 8px;
}

/*
    .pos-button--fab: Button modifier for round FAB (Floating Action Button) with shadow
*/
.pos-button--fab {
    padding: 0;
    transition: background-color 0.1s ease 0s;
    border-radius: 50%;
    box-shadow: 0 4px 4px $shadow;
}

.pos-button--fab .pos-button__button {
    padding: 8px;
}

.pos-button--fab.pos-button--lg .pos-button__button {
    padding: 16px;
}

.pos-button--fab.pos-button--small .pos-button__button {
    padding: 10px;
}

.pos-button--fab.pos-button--xs .pos-button__button {
    padding: 5px;
}

// Sizing

// Remix the default button sizing classes into new ones for easier manipulation.

.pos-button-group--xs .pos-button__button {
    @extend %pos-button--xs;
}

.pos-button-group--sm .pos-button__button {
    @extend %pos-button--sm;
}

.pos-button-group--lg .pos-button__button {
    @extend %pos-button--lg;
}

// Vertical button groups
// ----------------------

.pos-button-group--vertical > .pos-button {
    display: block;
    width: 100%;
    max-width: 100%;
}

.pos-button-group--vertical > .pos-button + .pos-button {
    margin-top: -1px;
    margin-left: 0;
}

.pos-button-group--vertical > .pos-button:not(:first-child):not(:last-child) {
    border-radius: 0;
}

.pos-button-group--vertical > .pos-button:first-child:not(:last-child) {
    @include border-top-radius($border-radius-base);
    @include border-bottom-radius(0);
}

.pos-button-group--vertical > .pos-button:last-child:not(:first-child) {
    @include border-top-radius(0);
    @include border-bottom-radius($border-radius-base);
}

// Optional: Group multiple button groups together for a toolbar
.pos-button-toolbar {
    display: flex;
    margin-left: -5px; // Offset the first child's margin
}

.pos-button-toolbar > .pos-button,
.pos-button-toolbar > .pos-button-group {
    margin-left: 5px;
}

// Buttons next to inputs
// --------------------------------------------------

.pos-button--form-row .pos-button__button {
    padding: 3px 10px;
}

/*
    .pos-button__text: Style for text element inside of a button
*/
.pos-button__text + .pos-button__icon {
    margin-left: 8px;
}

// Block button
// --------------------------------------------------

.pos-button--block {
    display: block;
    width: 100%;
}

// Vertically space out multiple block buttons
.pos-button--block + .pos-button--block {
    margin-top: 5px;
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
    &.pos-button--block {
        width: 100%;
    }
}

/*
    .pos-button__icon: Style for icon element inside of a button
    .pos-button__text: Style for text element inside of a button
*/
.pos-button__icon {
    padding: 2px;
}

.pos-button__icon + .pos-button__text {
    margin-left: 8px;
}
