cloud-info-popup {
    $icon-width: 150px;
    $icon-height: 125px;

    .cloud-info-popup__text {
        padding-top: $cloud-base-padding;
        padding-right: 2 * $cloud-base-padding;
        padding-left: 2 * $cloud-base-padding;
        white-space: pre-line;
    }

    .cloud-popup__title {
        padding-top: 8px;
        font-size: 16px;
    }

    .pos-input-checkbox .pos-input-checkbox__label {
        .pos-input-checkbox__border {
            border-color: $white;
            background-color: $color-c-l15;
        }

        &:hover {
            .pos-input-checkbox__border {
                border-color: $color-b-l82;
            }
        }
    }

    .cloud-info-popup__checkbox {
        display: flex;
        align-items: center;
        margin-left: $cloud-base-padding * 2;
    }

    .pos-button.cloud-popup__link-button {
        display: inline-block;
        margin: $cloud-base-padding * 2 0 $cloud-base-padding $cloud-base-padding * 2;
        background-color: $color-c;
        color: $color-d;
        text-align: center;
        white-space: nowrap;

        &:hover {
            background-color: $color-c-d20;
        }

        &:active {
            background-color: $color-c-d10;
        }

        .pos-icon-item__label {
            margin-left: $cloud-base-padding;
        }
    }

    [pos-button].pos-button .pos-svg-icon .pos-svg {
        padding: 0;
        fill: $color-d;
    }

    .pos-input-checkbox__input:checked + .pos-input-checkbox__border > .pos-input-checkbox__checker {
        background-image: linear-gradient(transparent 9px, $white 9px), linear-gradient(to right, transparent 5px, $white 5px);
    }

    .cloud-popup__icon {
        display: flex;
        align-items: center;
    }

    .cloud-popup__icon-column {
        flex-basis: 50%;

        .pos-svg-icon {
            width: $icon-width;
            height: $icon-height;

            svg {
                fill: $color-a;
            }
        }
    }

    .cloud-info-popup__text--left {
        padding-left: 0;
    }

    .cloud-popup__title--in-column {
        padding-top: 0;
        padding-right: 16px;
    }

    .cloud-popup__list--arrow .cloud-info-popup__action-info {
        display: none;
    }
}
