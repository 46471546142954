cloud-storage-info-message {
    .cloud-storage-info__message {
        display: block;
        width: 100%;
        margin: 0;
        padding: $cloud-base-padding;
    }

    @media (max-width: $breakpoint-small-max) {
        .cloud-storage-info__message {
            padding: $cloud-base-padding 0;
        }
    }

    @media (max-width: $breakpoint-small-min) {
        .cloud-storage-info__message {
            padding: 0;
        }
    }
}
