cloud-breadcrumb {
    display: flex;
    height: $cloud-breadcrumb-height;
    padding: 7px 0 7px 8px;

    .cloud-breadcrumb-menu {
        display: flex;
        flex-grow: 1;
        align-items: center;
        width: 0;
        margin: 0;
        padding: 0;
        overflow: hidden;

        .cloud-breadcrumb__segment,
        .cloud-breadcrumb__segment--more {
            color: $color-c;
        }

        .cloud-breadcrumb-cloud {
            .pos-svg-icon {
                line-height: 24px;
                fill: $color-b1;
            }
        }

        .cloud-breadcrumb-back,
        .cloud-breadcrumb-dropdown-button,
        .cloud-breadcrumb__segment {
            &:hover {
                cursor: pointer;

                .cloud-breadcrumb__segment-name {
                    cursor: pointer;
                }
            }
        }

        .cloud-breadcrumb__segment-icon {
            pos-svg-icon {
                vertical-align: middle;
            }

            .cloud-breadcrumb__segment-icon--arrow-down {
                padding-top: 5px;
                fill: $color-b;
            }

            .cloud-breadcrumb__segment-icon--arrow-next {
                fill: $color-c-l50;
            }
        }

        .cloud-breadcrumb__share-icon {
            vertical-align: top;
            margin-left: 0;
            fill: $color-c-l50;
            width: 24px;
            height: 24px;
            cursor: default;
        }

        .cloud-breadcrumb__segment-name {
            display: inline-block;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            .cloud-breadcrumb__share-icon {
                vertical-align: top;
                margin-left: 0;
                fill: $color-c-l50;
            }

            &:hover, &:active {
                .cloud-breadcrumb__share-icon {
                    fill: $color-b;
                }
            }
        }

        .cloud-breadcrumb__segment-name.cloud-dnd-over {
            background: $color-b-l82;
        }

        .cloud-breadcrumb__segment {
            display: flex;
            flex-grow: 0;
            flex-shrink: 0;
            align-items: center;
            max-width: calc(100% - 40px);
            list-style: none;

            .cloud-breadcrumb__segment-icon--arrow-down {
                display: none;
            }

            &:last-child {
                .cloud-breadcrumb__segment-name,
                .cloud-breadcrumb__segment-icon {
                    margin-right: 0;
                }

                .cloud-breadcrumb__segment-icon--arrow-next {
                    display: none;
                }
            }

            &:nth-child(2) {
                .cloud-breadcrumb__segment-icon--arrow-down {
                    display: inherit;
                }
            }
        }
    }
}

@media (min-width: $breakpoint-small-min) {
    .cloud-breadcrumb__segment {
        .cloud-breadcrumb__segment-icon--arrow-down {
            display: none;
        }
    }
}

@media (max-width: $breakpoint-extra-small-max) {
    cloud-breadcrumb {
        .cloud-breadcrumb-menu {
            .cloud-breadcrumb__segment--pre {
                display: none;
            }

            .cloud-breadcrumb__segment {
                max-width: 100%;
            }

            .cloud-breadcrumb__segment-name {
                &.cloud-breadcrumb__segment-name-extended {
                    max-width: $breakpoint-extra-small-max - 4 * $poseidon-button-width;
                }
            }
        }
    }
}

