// prevent scrolling of the whole app in ff [POS-791]
.app-contents {
    overflow: hidden;
}

.app-modal-message-wrapper {
    position: absolute;
    width: inherit;
    height: inherit;
    padding-top: 88px;
    z-index: 100;
    @include media-query(medium-large) {
        padding-top: 176px;
    }
}
