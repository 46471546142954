cloud-conflict-resolution-dialog {
    .cloud-conflict-resolution__icon-container {
        display: flex;
        flex-direction: column;
    }

    .cloud-conflict-resolution__icon {
        align-self: center;
        margin-bottom: $cloud-base-100pct;
    }

    .cloud-conflict-resolution__text {
        text-align: center;
    }

    .cloud-conflict-resolution__name {
        display: inline-block;
        max-width: 50%;
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: bottom;
    }

    .cloud-conflict-resolution__checkbox-wrapper {
        display: flex;
        align-items: center;
        padding-bottom: $cloud-base-padding;
    }
}
