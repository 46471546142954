* {
    @include box-sizing(border-box);
}

*::before,
*::after {
    @include box-sizing(border-box);
}

// remove default border on focus e.g. on the list item
*:focus {
    outline: none;
}

html {
    // prevent scrolling of the whole app on mobile devices [POS-657]
    overflow-x: hidden;
}

body {
    background-color: transparent;
    color: $font-color-base;
    font-family: $font-family-base;
    font-size: $font-size-base;
    line-height: $line-height-base;
}

// iOS "clickable elements" fix for role="button"
//
// Fixes "clickability" issue (and more generally, the firing of events such as focus as well)
// for traditionally non-focusable elements with role="button"
// see https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile

[role='button'] {
    cursor: pointer;
}

img {
    vertical-align: middle;
}
