html {
    background: none;
    overflow: hidden;
}

// breakpoints

$breakpoint-extra-small-max: 375px;
$breakpoint-small-min: $breakpoint-extra-small-max + 1;

// poseidon dimensions => should be exported variables

$poseidon-button-horizontal-padding: 12px;
$poseidon-button-vertical-padding: 6px;
$poseidon-svg-dimension: 24px;
$poseidon-button-width: 2 * $poseidon-button-horizontal-padding + $poseidon-svg-dimension + 2;
$poseidon-checkbox-width: $poseidon-svg-dimension + 8px * 2;

// sizes:
$cloud-base-layout-font: 16px;

$cloud-font-size-large: 18px;
$cloud-font-size-medium: 14px; // === $font-size-base !!!
$cloud-font-size-small: 12px;

$cloud-breadcrumb-width: 220px;
$cloud-breadcrumb-fade-out: $cloud-base-layout-font * 4;
$cloud-sidebar-width: 280px;
$cloud-sidebar-inner-width: calc(280px - 17px); // minus approx. scrollbar width
$cloud-mainarea-width: calc(100% - 280px);
$cloud-meta-info-width: 280px;

$cloud-menu-width: 265px;

$cloud-base-100pct: 16px;
$cloud-base-012pct: $cloud-base-100pct * 0.125;
$cloud-base-025pct: $cloud-base-100pct * 0.25;
$cloud-base-050pct: $cloud-base-100pct * 0.5;
$cloud-base-075pct: $cloud-base-100pct * 0.75;
$cloud-base-085pct: $cloud-base-100pct * 0.85;
$cloud-base-125pct: $cloud-base-100pct * 1.25;
$cloud-base-150pct: $cloud-base-100pct * 1.5;
$cloud-base-175pct: $cloud-base-100pct * 1.75;
$cloud-base-200pct: $cloud-base-100pct * 2;
$cloud-base-225pct: $cloud-base-100pct * 2.25;
$cloud-base-250pct: $cloud-base-100pct * 2.5;
$cloud-base-275pct: $cloud-base-100pct * 2.75;
$cloud-base-300pct: $cloud-base-100pct * 3;
$cloud-base-325pct: $cloud-base-100pct * 3.25;
$cloud-base-350pct: $cloud-base-100pct * 3.5;
$cloud-base-375pct: $cloud-base-100pct * 3.75;
$cloud-base-400pct: $cloud-base-100pct * 4;
$cloud-base-600pct: $cloud-base-100pct * 6;
$cloud-base-800pct: $cloud-base-100pct * 8;

$cloud-base-padding: $cloud-base-050pct;

$cloud-big-header-height: 64px;
$cloud-small-header-height: 52px;
$cloud-big-toolbar-height: 97px;
$cloud-small-toolbar-height: 48px;
$pos-footer-height: 54px;
$cloud-scroll-buffer: 1px;
$cloud-action-bar-height: 48px;
$cloud-breadcrumb-height: 48px;
$cloud-sidebarlink-height: $cloud-base-300pct;
$cloud-sidebarlinks-height: $cloud-sidebarlink-height * 5;
$cloud-storagebar-height: $cloud-base-250pct * 1.5;
$cloud-sidebarfooter-height: $cloud-base-250pct;
$cloud-language-height: $cloud-base-200pct;
$cloud-sidebarinfos-height: $cloud-sidebarlinks-height + $cloud-storagebar-height + $cloud-sidebarfooter-height;
$cloud-back-to-mail-height: $cloud-base-150pct + $cloud-base-padding;

// z-indices :

//* pleeeaaase no 999, 9999, 99999, 999999...s */
$cloud-zindex-sidebar: 4;
$cloud-zindex-detail-image: 4;
$cloud-zindex-detail-video: 5;
$cloud-zindex-detail-meta: 6;
$cloud-zindex-detail-navi: 6;
$cloud-zindex-detail-bar: 7;
$cloud-zindex-popup: 7;
$cloud-zindex-description-field: 7;
$cloud-zindex-tutorial-box: 7;
/* drag info z-index should be > 10000, above core modal layer */
$cloud-zindex-drag-info: 10001;

// colors:
$cloud-transparent: rgba($white, 0%);
$cloud-share-from: rgba($black, 0.2);
$cloud-share-to: rgba($black, 0.3);
$cloud-description-background: rgba($black, 0.5);
$cloud-meta-info-background: $cloud-description-background;
$cloud-shadow: rgba($black, 0.25);
$cloud-play-icon-background: rgba($color-c, 0.1);

body {
    -webkit-tap-highlight-color: transparent; // sass-lint:disable-line no-vendor-prefixes  (webkit-only property)
}

.cloud-element-wrapper {
    display: inline-block;
}

// used als wrapper for inline or block elements

.app-contents-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

@media (max-width: $breakpoint-small-max) {
    .cloud-hidden-for-tablet {
        display: none;
    }
}

@media (min-width: $breakpoint-medium-min) {
    .cloud-visible-for-tablet {
        display: none;
    }
}

@media (max-width: $breakpoint-extra-small-max) {
    .cloud-hidden-for-mobile {
        display: none;
    }
}

@media (min-width: $breakpoint-small-min) {
    .cloud-visible-for-mobile {
        display: none;
    }
}

// mixins:

@mixin mixin-animation($name, $duration: 1000ms, $timing: ease, $iteration: infinite, $direction: normal, $delay: 0ms) {
    animation: $name $duration $timing $delay $iteration $direction;
}

@mixin mixin-calc($property, $expression, $fallback) {
    #{$property}: #{$fallback};
    #{$property}: calc(#{$expression}); // sass-lint:disable-line no-duplicate-properties (fallback logic)
}

@mixin mixin-vertical-linear-gradient($color-stop-1, $stop-1, $color-stop-2, $stop-2) {
    background: linear-gradient(to bottom, $color-stop-1 $stop-1, $color-stop-2 $stop-2);
}

@mixin mixin-horizontal-linear-gradient($color-stop-1, $stop-1, $color-stop-2, $stop-2) {
    background: linear-gradient(to right, $color-stop-1 $stop-1, $color-stop-2 $stop-2);
}

@mixin mixin-radial-gradient($shape, $position-from, $position-to, $color-stop-1, $stop-1, $color-stop-2, $stop-2, $color-stop-3, $stop-3) {
    background: radial-gradient($shape at $position-from $position-to, $color-stop-1 $stop-1, $color-stop-2 $stop-2, $color-stop-3 $stop-3);
}

// extends:

%cloud-align-middle {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

%cloud-text-ellipsis {
    width: 100%;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

%cloud-list {
    position: absolute;
    left: 0;
    width: 100%;
    @include mixin-calc(height, '100% - ' + ($cloud-action-bar-height + $cloud-breadcrumb-height), '89%');
    padding: 0;
}

%cloud-function-svg {
    width: $cloud-base-125pct;
    height: $cloud-base-125pct;
    margin: $cloud-base-012pct;
}

.cloud-function-icon {
    .pos-svg-icon {
        svg {
            @extend %cloud-function-svg;
        }
    }
}

.pos-table__row--expired {
    .collection-icon {
        &.pos-svg-icon {
            svg {
                fill: transparent;
                stroke: $color-c-l50;
                }
            }
    }
}

.pos-table__row--active:not(:hover) {
    .collection-icon {
            &.pos-svg-icon {
                svg {
                    fill: transparent;
                    stroke: white;
                }
            }
    }
}

.cloud-tile-item--expired {
        .collection-icon {
            &.pos-svg-icon {
                svg {
                    fill: transparent;
                    stroke: $color-c-l50;
            }
        }
    }
}

.collection-icon {
    &.pos-svg-icon {
        svg {
            fill: transparent;
            stroke: $color-b;
        }
    }
}

%cloud-info-svg {
    width: $cloud-base-100pct;
    height: $cloud-base-100pct;
    margin: $cloud-base-025pct;
}

.cloud-info-icon {
    .pos-svg-icon {
        svg {
            @extend %cloud-info-svg;
        }
    }
}

/* center spinner & color icon in dialogs e.g. prepare download dialog */
.pos-message-modal__body {
    .pos-spinner {
        margin: auto;
    }

    .pos-message__icon--encrypted,
    .pos-message__icon--cloud-function,
    .pos-message__icon--folder {
        svg {
            fill: $color-b;
        }
    }
}

.noscript-warning {
    width: 50%;
    margin: auto;
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: $color-h1-l70;
}

.noscript-warning .pos-svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;
    padding: 2px;
    fill: $color-h1;
}

.cloud-hl {
    margin: 0;
    color: $color-c-l15;
    font-weight: normal;
}

.cloud-hl--1 {
    margin-bottom: 24px;
    font-size: 24px;
    line-height: 32px;
}

.cloud-hl--2 {
    margin-bottom: 16px;
    font-size: 20px;
    line-height: 28px;
}

.cloud-hl--3 {
    font-size: 16px;
    line-height: 24px;
}

// MAMNGC-6135 dialog buttons are not clickable in Edge browser
.pos-dialog-modal-layer {
    position: absolute;
}

.app-contents-wrapper {
    height: 100%;
    overflow: hidden;
}

/* cloud styles */
.pos-header--small {
    .pos-brand-title {
        display: none;
    }

    .pos-brand-logo {
        margin-right: 12px;
    }
}
