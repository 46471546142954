cloud-editshare-dialog {

    pos-form-message,
    .cloud-editshare__permission-hint,
    .cloud-editshare__pin-hint {
        padding-bottom: $cloud-base-padding;
        font-size: $cloud-font-size-small;
    }

    .cloud-editshare__expiration {
        height: 32px;

        span {
            padding-right: $cloud-base-padding;
        }
    }

    .cloud-editshare-distance {
        padding-bottom: $cloud-base-padding;
    }

    .cloud-editshare__duration-hint,
    .cloud-editshare__pin-checkbox {
        display: flex;
        align-items: center;
        padding-bottom: $cloud-base-padding;
    }

    .cloud-editshare__duration {
        font-size: $cloud-font-size-small;
    }

    .cloud-shareinfo__link-container {
        margin-top: $cloud-base-padding;
        padding: $cloud-base-padding;
        background-color: $color-b-l94;
        text-align: center;

        .cloud-shareinfo__link {
            display: block;
            padding-bottom: $cloud-base-padding;
            color: $black;
            text-decoration: none;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;

            &:hover {
                text-decoration: underline;
                cursor: pointer;
            }
        }
    }

    .cloud-editshare__pin-input-wrapper {
        position: relative;

        .cloud-editshare__pin-input-overlay {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    pos-input {
        option {
            background-color: $color-d;
            color: $color-c;
        }

        option:hover {
            background-color: $color-b;
            color: $color-d;
        }
    }

    .cloud-editshare__pin--hidden {
        position: absolute;
        height: 0;
        visibility: hidden;
    }
}
