cloud-grid-item {
    padding: 4px;
    cursor: pointer;

    pos-input-checkbox {
        position: absolute;
        top: 0;
        left: 0;
        padding-left: $cloud-base-padding;

        .pos-input-checkbox__label {
            display: block;
            padding: $cloud-base-050pct;
        }

        .pos-input-checkbox__border {
            border-color: $color-c-l50;
            background-color: $color-d;
        }

        &:not(:hover) {
            .pos-input-checkbox__border {
                border-color: $color-c-l50;
                background-color: $color-d;
            }
        }

        // sass-lint:disable no-vendor-prefixes
        @media (-moz-touch-enabled: 1), (pointer: coarse) {
            .pos-input-checkbox__border {
                border-color: $color-b1;
                background-color: $color-c-l82;
            }
        }

        &.pos-input-checkbox--edit-mode {
            visibility: visible;
        }
    }

    .cloud-tile-item__checkbox--inactive {
        visibility: hidden;
    }

    .cloud-tile-item__container {
        position: relative;
        height: 100%;
    }

    .cloud-tile-item__content {
        width: 100%;
        height: 100%;
        background-repeat: no-repeat;
        overflow: hidden;
        border: 1px solid $color-b1-l82;
        border-radius: $cloud-base-025pct;

        &.cloud-tile-item__content--folder {
            border: 0;
            background-color: $color-b1-l94;
        }

        &.cloud-tile-item__content--visual-media {
            border-radius: 0;
            border: 0;
            background-color: $color-b1-l94;
            animation: none;
            background-position: 0 0;

            &.cloud-tile-item__content--thumbnail-loading {
                background-image: linear-gradient(
                    90deg,
                    rgba($color-d, 0),
                    rgba($color-d, 0.2),
                    rgba($color-d, 0)
                );
                background-position: left -300px top 0;
                animation: cloud-tile-item-content-thumbnail-loading-animation 2s ease infinite;

                @keyframes cloud-tile-item-content-thumbnail-loading-animation {
                    to {
                        background-position: right -300px top 0;
                    }
                }
            }
        }
    }

    .cloud-tile-item__selector-inner--visual-media {
        border: $cloud-base-100pct solid $color-b1-l82;
    }

    .cloud-tile-item__malware-icon {
        position: absolute;
        top: 0;
        right: 0;
        padding: $cloud-base-padding;
    }

    .cloud-tile-item__name-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $cloud-base-025pct;
        border-top: 1px solid $color-b1-l82;
        background-color: $color-b1-l82;

        &.cloud-tile-item__name-container--folder,
        &.cloud-tile-item__name-container--visual-media {
            border: 0;
            justify-content: center;
            background: none;
        }

        .cloud-tile-item__name {
            @extend %cloud-text-ellipsis;
            flex-basis: content;
            flex-grow: 1;
            width: auto;
            font-size: 12px;
            line-height: $cloud-base-150pct;
            text-align: left;

            &.cloud-tile-item__name--folder,
            &.cloud-tile-item__name--visual-media.cloud-tile-item__name--no-thumbnail {
                flex-grow: 0;
                text-align: center;
            }

            &.cloud-tile-item__name--visual-media.cloud-tile-item__name--thumbnail {
                visibility: hidden;
            }
        }

        .cloud-tile-item__type-icon {
            margin-right: $cloud-base-025pct;
        }

        .cloud-tile-item__share-icon {
            margin-left: $cloud-base-025pct;
            fill: $color-c-l50;

            &.cloud-tile-item__share-icon--visual-media.cloud-tile-item__share-icon--thumbnail {
                fill: $color-d;
                position: absolute;
                right: 0;
                bottom: 0;
                width: $cloud-base-250pct;
                height: $cloud-base-250pct;
                padding: $cloud-base-050pct $cloud-base-100pct $cloud-base-050pct 0;
                background: radial-gradient(farthest-corner at 90% 90%, rgba($black, 0.2) 0%, rgba($black, 0.3) 40%, transparent 70%);
            }
        }
    }

    .cloud-tile-item__type {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        &.cloud-tile-item__type--thumbnail.cloud-tile-item__type--folder pos-svg-icon {
            fill: $color-b1-l94;
            background-size: 70px 70px;
            background-repeat: no-repeat;
            background-position: 1px 1px;
        }
    }

    .cloud-tile-item--disabled {
        .cloud-tile-item__name {
            color: $color-c-l50;
        }
    }

    &.cloud-dnd-over {
        background-color: $color-b1-l94;
    }

    .cloud-tile-item__clickarea {
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    .cloud-tile-item__selector-inner {
        width: 100%;
        height: 100%;
    }

    .pos-svg-icon.cloud-tile-item__video-play-button {
        @extend %cloud-align-middle;
        width: $cloud-base-200pct;
        height: $cloud-base-200pct;
        border-radius: $cloud-base-100pct;
        background-color: $cloud-play-icon-background;
        fill: $color-d;
    }

    .pos-svg-icon.cloud-tile-item__thumbnail-failure {
        @extend %cloud-align-middle;
        width: $cloud-base-400pct;
        height: $cloud-base-400pct;
        fill: $color-c;
    }

    .cloud-tile-item--selected {
        pos-input-checkbox {
            visibility: visible;
        }

        .cloud-tile-item__content,
        .cloud-tile-item__name-container {
            border-color: $color-b1-l94;
        }

        .cloud-tile-item__type,
        .cloud-tile-item__name-container {
            .pos-svg-icon {
                fill: $color-b1;
            }
        }

        .cloud-tile-item__name-container--visual-media {
            background: none;
            color: $black;
        }
    }

    &:not(:hover) {
        .cloud-tile-item__selector-inner--visual-media {
            border-color: transparent;
        }

        pos-input-checkbox {
            visibility: hidden;
        }

        .cloud-tile-item__content {
            background-color: transparent;
            border-color: $color-c-l82;
        }

        .cloud-tile-item__name-container {
            background-color: $color-c-l94;
            border-color: $color-c-l82;

            &.cloud-tile-item__name-container--folder,
            &.cloud-tile-item__name-container--visual-media {
                background: none;
            }
        }

        .cloud-tile-item__type.cloud-tile-item__type--thumbnail.cloud-tile-item__type--folder pos-svg-icon {
            fill: $color-d;
        }

        .cloud-tile-item__name--visual-media {
            color: $black;
        }

        .cloud-tile-item--selected {
            .cloud-tile-item__selector-inner--visual-media {
                border-color: $color-b1;

                .pos-svg-icon {
                    fill: $color-d;
                }
            }

            .cloud-tile-item__type.cloud-tile-item__type--thumbnail.cloud-tile-item__type--folder pos-svg-icon {
                fill: $color-b1;
            }

            .cloud-tile-item__content {
                border-color: $color-b1;

                &.cloud-tile-item__content--visual-media,
                &.cloud-tile-item__content--folder {
                    color: $color-d;
                    background-color: $color-b1;

                    .pos-svg-icon {
                        fill: $color-d;
                    }

                    .cloud-grid-item-share-icon {
                        background: none;
                    }
                }
            }

            .cloud-tile-item__name-container {
                border-color: $color-b1;
                background-color: $color-b1;
                color: $color-d;

                .cloud-tile-item__type-icon.pos-svg-icon,
                .cloud-tile-item__share-icon {
                    fill: $color-d;
                }

                &.cloud-tile-item__name-container--folder,
                &.cloud-tile-item__name-container--visual-media {
                    background: none;

                    .pos-svg-icon {
                        fill: $color-d;
                    }
                }
            }

            .cloud-tile-item__name--visual-media {
                color: $color-d;
            }

            pos-input-checkbox {
                visibility: visible;
            }
        }
    }
}

@media (min-width: 410px) and (max-width: 450px) {
    cloud-grid-item {
        .cloud-tile-item__name-container {
            margin-top: -10px;
            position: relative;
        }

        .cloud-tile-item__type {
            padding-top: 20px;
        }
    }
}
