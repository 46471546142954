cloud-image-view {
    display: block;
    width: 100%;
    height: 100%;

    .pos-spinner__bar {
        background-color: $white;
    }

    &.cloud-image-view--full {
        width: 100%;
        height: 100%;

        .cloud-image-view__image-container {
            display: table;
            position: absolute;
            width: 100%;
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            text-align: center;
            z-index: $cloud-zindex-detail-image;
        }

        .cloud-image-view__vertical-spacer {
            display: table-cell;
            max-width: 100%;
            max-height: 100%;
            vertical-align: middle;
        }

        .cloud-image-view__horizontal-spacer {
            max-width: 100%;
            max-height: 100%;
            margin-right: auto;
            margin-left: auto;
        }

        .cloud-image-view__image {
            position: relative;
            margin: auto;
            user-select: none;
        }

        .cloud-image-view__thumbnail-failure-icon {
            width: $cloud-base-800pct;
            height: $cloud-base-800pct;
            fill: $color-d;
            margin: auto;
        }

        .cloud-image-view__thumbnail-failure-text {
            margin: auto;
            color: $color-d;
        }
    }

    &.cloud-image-view--preload {
        display: none;
        width: 0;
        height: 0;

        img {
            display: none;
            width: 0;
            height: 0;
        }
    }
}

