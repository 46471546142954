cloud-rename-dialog {
    .cloud-rename__icon-container {
        display: flex;
        flex-direction: column;
    }

    .cloud-rename__icon {
        align-self: center;
        margin-bottom: $cloud-base-100pct;
    }

    .cloud-rename__text {
        max-width: 285px;
        margin: 0 auto 15px;
        text-align: center;
    }
}
