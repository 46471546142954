cloud-dialog-mode-file-name-input {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: $cloud-base-padding $cloud-base-padding $cloud-base-padding $cloud-sidebar-width;
    border-bottom: 1px solid $modal-content-border-color;
    background-color: $white;

    .cloud-dialog-mode-footer__multi-file-text {
        color: $color-c-l15;
        font-weight: bold;
    }

    .cloud-dialog-mode-footer__list-errors {
        margin: 0;
        padding: 0 0 0 30px;
        list-style-type: none;

        li {
            height: 25px;
        }
    }

    cloud-file-name-input {
        flex-grow: 1;
        max-width: $cloud-sidebar-width;
    }

    .pos-icon-item__icon {
        display: flex;
        justify-content: center;

        .pos-svg {
            width: 32px;
            height: 32px;
        }
    }
}
