$mobile-ad-height: 90px;

.hastransision {
    @include transition(transform 0.2s ease-in-out 0s);
}

.pos-column-layout {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.pos-column-layout--notresponsive {
    display: flex;
    flex-wrap: nowrap;
}

@include media-query(medium-landscape-large) {
    .pos-column-layout {
        display: flex;
    }
}

.pos-column-layout__column1 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 280px;
    transform: translate3d(-200%, 0, 0);
    z-index: 1;
}

.pos-column-layout--notresponsive .pos-column-layout__column1 {
    position: relative;
    transform: translate3d(0, 0, 0);
    z-index: 0;
}


.pos-app-layout--hasfooter .pos-column-layout__column1 {
    bottom: -60px;
}

@include media-query(medium-landscape-large) {
    .pos-column-layout__column1 {
        position: relative;
        transform: translate3d(0, 0, 0);
        z-index: 0;
    }
}

.pos-column-layout__column1--overlay-column1 {
    transform: translate3d(0, 0, 0);
    z-index: 4;
}

.pos-column-layout__content-wrapper {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.pos-column-layout--notresponsive .pos-column-layout__content-wrapper {
    position: relative;
    flex: 1;
}

@include media-query(medium-landscape-large) {
    .pos-column-layout__content-wrapper {
        position: relative;
        flex: 1;
    }
}

.pos-column-layout__content-wrapper--show-column3 {
    transform: translate3d(-100%, 0, 0);
}

@include media-query(medium-large) {
    .pos-column-layout__content-wrapper--show-column3 {
        transform: translate3d(0, 0, 0);
    }
}

.pos-column-layout__column2 {
    flex: 1;
    min-width: 280px;
}

.pos-column-layout--notresponsive .pos-column-layout__column2 {
    flex: 4;
}

@include media-query(medium-large) {
    .pos-column-layout__column2 {
        flex: 4;
    }
}

.pos-column-layout__column3 {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translate3d(100%, 0, 0);
}

@include media-query(medium-portrait) {
    .pos-column-layout__column3 {
        position: relative;
        flex: 8;
        transform: translate3d(0, 0, 0);
    }
}

@include media-query(medium-landscape-large) {
    .pos-column-layout__column3 {
        position: relative;
        flex: 8;
        transform: translate3d(0, 0, 0);
    }
}

// Ads

.pos-column-layout__ad {
    display: none;
    position: absolute;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    height: $mobile-ad-height;
}

.pos-column-layout--show-ad .pos-column-layout__ad {
    display: block;
}

.pos-column-layout--show-ad .pos-column-layout__column2 {
    bottom: $mobile-ad-height;
}

@include media-query(medium) {
    .pos-column-layout--show-ad .pos-column-layout__column2,
    .pos-column-layout--show-ad .pos-column-layout__column3 {
        margin-bottom: $mobile-ad-height;
    }
}

@include media-query(large) {
    .pos-column-layout__ad {
        position: relative;
        width: 170px;
        height: 100%;
    }
}
