cloud-list-group {
    display: block;

    .disable-group {
        display: block;
        opacity: 0.2;

        .pos-table__row,
        cloud-grid-item {
            cursor: pointer;
            pointer-events: none;
        }
    }

}
