cloud-search-field {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: $cloud-base-padding;

    .pos-input {
        .pos-input--search {
            height: $cloud-base-200pct;
            padding-right: $cloud-base-250pct;
        }
    }

    .pos-svg-icon {
        fill: $color-c-l50;
    }

    .cloud-popup__entry {
        line-height: 24px;
    }

    .cloud-popup__search-suggestions-menu .cloud-popup__entry:hover {
        background-color: inherit;
    }

    .cloud-popup__search-suggestions-menu .cloud-popup__list--menu .cloud-popup__entry--selected {
        background-color: $color-b1-l82;
    }

    .cloud-popup__entry--suggestion-icon {
        padding-right: $cloud-base-padding;

        pos-svg-icon {
            width: 24px;
            height: 24px;
            fill: $color-c-l50;
            vertical-align: middle;
            margin-top: - $cloud-base-padding * 0.5;
        }
    }

    .cloud-popup__entry--suggestion-name {
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .cloud-popup__entry--suggestion-count {
        flex-grow: 0;
        flex-shrink: 0;
        background-color: $color-c-l82;
        border-radius: $border-radius-base;
        padding: 0 2px;
    }
}
