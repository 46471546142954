$border-radius-base: 4px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;

// Breakpoints:
$breakpoint-medium-min: 768px;
$breakpoint-medium-landscape-min: 992px;
$breakpoint-large-min: 1200px; /* 1200px */
$breakpoint-small-max: $breakpoint-medium-min - 1; /* 767px */
$breakpoint-medium-max: $breakpoint-large-min - 1; /* 1199px */
$breakpoint-medium-portrait-max: $breakpoint-medium-landscape-min - 1; /* 991px */

$white: #fff;
$black: #000;

$color-h1: #f0bc00;
$color-h1-l15: mix($white, $color-h1, 15%);
$color-h1-l30: mix($white, $color-h1, 30%);
$color-h1-l40: mix($white, $color-h1, 40%);
$color-h1-l50: mix($white, $color-h1, 50%);
$color-h1-l70: mix($white, $color-h1, 70%);
$color-h1-l82: mix($white, $color-h1, 82%);
$color-h1-l94: mix($white, $color-h1, 94%);
$color-h1-d10: mix($black, $color-h1, 10%);
$color-h1-d20: mix($black, $color-h1, 20%);

$color-h2: #d40000;
$color-h2-l15: mix($white, $color-h2, 15%);
$color-h2-l30: mix($white, $color-h2, 30%);
$color-h2-l40: mix($white, $color-h2, 40%);
$color-h2-l50: mix($white, $color-h2, 50%);
$color-h2-l70: mix($white, $color-h2, 70%);
$color-h2-l82: mix($white, $color-h2, 82%);
$color-h2-l94: mix($white, $color-h2, 94%);
$color-h2-d10: mix($black, $color-h2, 10%);
$color-h2-d20: mix($black, $color-h2, 20%);

$color-h3: #5cb82a;
$color-h3-l15: mix($white, $color-h3, 15%);
$color-h3-l30: mix($white, $color-h3, 30%);
$color-h3-l40: mix($white, $color-h3, 40%);
$color-h3-l50: mix($white, $color-h3, 50%);
$color-h3-l70: mix($white, $color-h3, 70%);
$color-h3-l82: mix($white, $color-h3, 82%);
$color-h3-l94: mix($white, $color-h3, 94%);
$color-h3-d10: mix($black, $color-h3, 10%);
$color-h3-d20: mix($black, $color-h3, 20%);

$color-h4: #929191;
$color-h4-l15: mix($white, $color-h4, 15%);
$color-h4-l30: mix($white, $color-h4, 30%);
$color-h4-l40: mix($white, $color-h4, 40%);
$color-h4-l50: mix($white, $color-h4, 50%);
$color-h4-l70: mix($white, $color-h4, 70%);
$color-h4-l82: mix($white, $color-h4, 82%);
$color-h4-l94: mix($white, $color-h4, 94%);
$color-h4-d10: mix($black, $color-h4, 10%);
$color-h4-d20: mix($black, $color-h4, 20%);

//
// Variables
// --------------------------------------------------

//== Colors
//

//== Modals
$modal-layer-background-color: rgba(#333, 0.5);

//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$typography-tags: false !default;
$typography-classes: false !default;

//### Font colors
$font-color-base: #333 !default;
$font-color-header: mix($white, $font-color-base, 15%) !default;
$font-color-secondary: mix($white, $font-color-base, 50%) !default;

//### Typography
// copy-12
$font-size-small: 12px !default;
$line-height-small: 18px !default;
// copy-14
$font-size-base: 14px !default;
$line-height-base: 20px !default;
// copy-16
$font-size-large: 16px !default;
$line-height-large: 24px !default;

$font-family-base: Verdana, 'Helvetica Neue', Helvetica, Arial, sans-serif !default;
$font-family-header: var(--font-family-header) !default;
$font-family-hero: var(--font-family-hero) !default;

//### Font weight (don't forget to provide font-faces for 400|500|700 if you use them)
$font-weight-header: var(--font-weight-header) !default;

//== Components

//== Modals
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, 0.2) !default;
//** Modal footer border color
$modal-footer-border-color: #e5e5e5 !default;
