cloud-malware-info-dialog {

    text-align: center;

    .cloud-malware-info-dialog__icon-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .cloud-malware-info-dialog__title {
        line-height: 24px;
        font-size: 16px;
        margin: 12px 0;
    }
}
