cloud-pin-input-external {
    display: flex;
    flex-direction: column;

    cloud-scroll-area {
        flex-grow: 1;
        flex-basis: 0;
    }

    .cloud-pin-input-external__wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 320px;
        padding: ($cloud-base-padding * 2) $cloud-base-padding $cloud-base-padding;

        .cloud-pin-input-external--encrypted-icon {
            margin: $cloud-base-padding * 2;
            fill: $color-b;
            width: 64px;
            height: 64px;
            align-self: center;
        }

        .cloud-pin-input-external-wrapper__title {
            text-align: center;
        }

        .cloud-pin-input-external-wrapper__content {
            margin-bottom: $cloud-base-padding * 3;
            text-align: center;
        }

        .cloud-pin-input-external-wrapper__input-text {
            width: 100%;
        }

        .cloud-pin-input-external-wrapper__error {
            color: $color-h2;
            margin: $cloud-base-padding;
            display: flex;
            align-items: center;
        }

        .pos-message__icon--error {
            margin-right: 4px;
        }

        .cloud-pin-input-external-wrapper__cta {
            margin-top: $cloud-base-padding * 2;
            justify-content: center;
        }

        .cloud-pin-input-external-wrapper--full-width {
            width: 100%;
        }
    }
}
