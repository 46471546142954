cloud-download-ready-dialog {
    text-align: center;

    .cloud-download-ready__heading {
        margin: 0 auto 24px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .cloud-download-ready__text {
        max-width: 285px;
        margin: auto;
    }

    .pos-spinner {
        margin: 0 auto;
    }
}
