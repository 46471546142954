cloud-carousel {
    display: block;

    .cloud-carousel__content {
        position: relative;
        text-align: center;
        overflow: hidden;
    }

    .cloud-carousel__slides-container {
        display: table;
        transition: transform 0.3s ease-out;
        border-collapse: collapse;
    }

    .cloud-carousel__navigation-arrows {
        display: block;
        position: absolute;
        top: 50%;
        width: 100%;
        margin-top: -45px;
    }

    .cloud-carousel__navigation-arrow {
        opacity: 0;

        &.cloud-carousel__navigation-arrow--visible {
            opacity: 0.3;

            &:hover {
                opacity: 1;
            }
        }

        &.cloud-carousel__navigation-arrow--prev {
            margin-left: 5px;
            float: left;
        }

        &.cloud-carousel__navigation-arrow--next {
            margin-right: 5px;
            float: right;
        }
    }

    .cloud-carousel__navigation-dots {
        height: 16px;
    }

    .cloud-carousel__navigation-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 2px;
        border: 2px solid $color-c-l70;
        border-radius: 50%;
        background: $color-d;
        cursor: pointer;
        box-sizing: content-box;

        &.cloud-carousel__navigation-dot--active {
            border: 2px solid $color-b;
            background-color: $color-b;
        }
    }
}
