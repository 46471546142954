cloud-main-view {
    .pos-app-header {
        display: flex;
        flex-direction: column;
        min-height: auto;
    }

    .cloud-header__product-name--visible {
        .pos-brand-title {
            display: block;
        }

        .pos-brand-logo,
        .pos-header__content {
            width: 100%;
        }
    }

    .pos-column-layout__column1 {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .cloud-sidebar--dialog,
    .cloud--has-header cloud-sidebar {
        background-color: $color-c-l94;
    }

    cloud-logout-button,
    cloud-register-box {
        position: absolute;
        top: 0;
        right: 0;
    }

    .cloud-sidebar--dialog .cloud-sidebar__scroll {
        padding-top: 8px;
    }

    .pos-app-content,
    .pos-column-layout__column2 {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        section {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-width: 0;
        }
    }

    .cloud-main-view__content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    .cloud-main-view__content-body {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        background-color: $color-d;
    }

    .cloud-main-view__content-body-inner {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        & > * {
            flex-grow: 1;
        }
    }

    cloud-pin-input-external {
        flex-grow: 1;
    }

    .pos-app-footer {
        border-top: 1px solid $color-c-l82;
        height: 54px;
    }

    &.cloud-main-view--dialog .pos-app-footer {
        height: auto;
    }
}
