cloud-list-item {
    display: block;

    &:last-of-type {
        padding-bottom: 88px;

        .pos-table-row--mouseover {
            height: inherit;
        }
    }

    &.cloud-dnd-over {
        background-color: $color-b1-l94;
    }

    .pos-svg-icon.cloud-list-item__share-icon {
        width: 24px;
        height: 24px;

        svg {
            fill: $color-c-l50;
        }
    }

    .cloud-list-item__thumbnail {
        background-color: $color-c-l70;
    }

    .cloud-list-item__video-play-icon {
        position: absolute;
        width: 24px;
        height: 24px;
        margin-top: 10px;
        margin-left: 4px;
    }

    .pos-table__cell--preview {
        &:hover {
            .cloud-list-item__video-play-icon {
                position: absolute;
                width: 48px;
                height: 48px;
                margin-top: -2px;
                margin-left: -8px;
                z-index: 2;
            }
        }
    }

    .hide-checkbox {
        visibility: hidden;
    }

    .pos-table__row {
        border-bottom: 1px solid $color-c-l82;
    }

    .pos-table__row--last {
        border-bottom: 0;
    }

    .pos-table__row--hovered,
    .pos-table__row--quick-action,
    .pos-table__row:hover {
        .pos-table__cell--hide-on-mouseover {
            visibility: hidden;
        }

        .pos-table-row--mouseover {
            display: flex;
            top: 0;
        }
    }

    .pos-table__cell--placeholder {
        width: 50%;
        margin: auto;
    }

    .pos-table__row--hovered {
        background-color: $color-b1-l94;
    }

    .pos-table-row--mouseover {
        display: flex;
        position: absolute;
        right: -100%;
        bottom: 1px;
        align-items: center;
        width: auto;
        padding: 0 14px;
        transition: right 1s;
        background-color: $color-b1-l94;

        &.pos-table-row--mouseover-visible {
            right: 0;
        }

        &.pos-table-row--mouseover-desktop {
            display: none;
        }

        .pos-button--link {
            flex-grow: 0;
            flex-shrink: 1;
            padding: 4px 20px;

            .pos-svg-icon .pos-svg {
                padding: 0;
            }
        }

        .pos-button--link:last-child {
            margin-right: 0;
        }

        .pos-button--link:active {
            background: $color-b1-l50;
        }
    }
}
