.pos-svg-icon {
    display: inline-flex;
}

.pos-svg-icon .pos-svg {
    width: inherit;
    overflow: visible;
    // workaround for IE11 and Edge click on use tag returning null for target
    pointer-events: none;
}

.pos-svg-icon--48 {
    width: 48px;
    height: 48px;
}

.pos-svg-icon--32 {
    width: 32px;
    height: 32px;
}

.pos-svg-icon--24 {
    width: 24px;
    height: 24px;
}

.pos-svg-icon--16 {
    width: 16px;
    height: 16px;
}
