cloud-search {
    display: flex;
    flex-direction: column;

    cloud-tool-bar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    cloud-scroll-area {
        flex-grow: 1;
    }

    .cloud-search__empty {
        padding: $cloud-base-padding * 3 $cloud-base-padding * 4;
        text-align: center;
    }

    pos-spinner {
        margin: auto;
    }

    .cloud-search-trash-title {
        display: flex;
        align-items: center;
        height: 44px;
        padding-left: 52px;
        font-size: 12px;
        font-weight: bold;
    }

    .cloud-search__trash-link {
        display: flex;
        align-items: center;
        height: 44px;
        padding-left: 52px;
    }

    .cloud-search__spinner--bottom {
        margin-top: -30px;
    }
}
