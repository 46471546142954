.pos-table {
    width: 100%;
    max-width: 100%;
    margin-bottom: $line-height-base;
}

.pos-table--content-right {
    text-align: right;
}

.pos-table--content-center {
    text-align: center;
}

.pos-table--content-left {
    text-align: left;
}

.pos-table .pos-svg-icon {
    display: flex;
    fill: $color-b1;
}

.pos-table .pos-svg {
    width: inherit;
    overflow: visible;
}

.pos-table__row {
    min-height: 22px;
    border-top: 1px solid $color-c-l82;
    cursor: pointer;
}

.pos-table__head .pos-table__row,
.pos-table__body .pos-table__row:first-child {
    border-top: 0;
}

.pos-table__body .pos-table__row--active {
    background-color: $color-b1;
}

.pos-table__body .pos-table__row--active:not(:hover) .pos-table__cell {
    color: $color-d;
}

.pos-table__body .pos-table__row--active:not(:hover) .pos-svg {
    fill: $color-d;
}

.pos-table__body .pos-table__row--active:not(:hover) .pos-input-checkbox__border {
    border-color: $color-d;
}

.pos-table__body .pos-table__row--active:not(:hover) .pos-input-checkbox__checker {
    background: linear-gradient(to bottom, transparent 9px, $color-b1 9px), linear-gradient(to right, transparent 5px, $color-b1 5px);
}

.pos-table__body .pos-table__row--active:not(:hover) .pos-input-checkbox__labeltext {
    color: $color-d;
}

.pos-table__body .pos-table__row:hover {
    background-color: $color-b1-l82;
}


@media (-moz-touch-enabled: 1), (pointer: coarse) {
    .pos-table__body .pos-table__row {
        cursor: unset;
    }

    .pos-table__body .pos-table__row:hover {
        background-color: inherit;
    }

    .pos-table__body .pos-table__row--active:hover {
        background-color: $color-b1;
    }

    .pos-table__body .pos-table__row--active:hover .pos-table__cell {
        color: $color-d;
    }

    .pos-table__body .pos-table__row--active:hover .pos-svg {
        fill: $color-d;
    }

    .pos-table__body .pos-table__row--active:hover .pos-input-checkbox__border {
        border-color: $color-d;
    }

    .pos-table__body .pos-table__row--active:hover .pos-input-checkbox__checker {
        background: linear-gradient(to bottom, transparent 9px, $color-b1 9px), linear-gradient(to right, transparent 5px, $color-b1 5px);
    }

    .pos-table__body .pos-table__row--active:hover .pos-input-checkbox__labeltext {
        color: $color-d;
    }
}

.pos-table__cell {
    margin: 0;
    padding: 0;
    @include text-overflow;
    line-height: 22px;
}
