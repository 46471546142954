cloud-cta-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: $cloud-base-padding;

    .pos-button-group {
        display: flex;
    }

    [pos-button].pos-button {
        flex-grow: 1;

        .pos-svg-icon {
            margin-right: $cloud-base-padding;
        }
    }

    .cloud-upload-button__button,
    .cloud-create-button__button {
        justify-content: center;
    }
}
