a,
a:hover,
a:focus,
a.active {
    outline: 0;
    color: $color-link;
    text-decoration: none;
    cursor: pointer;
}

a:hover {
    text-decoration: underline;
}
