cloud-scroll-area {
    display: flex;
    flex-direction: column;

    .cloud-scroll-area {
        flex-grow: 1;
        flex-basis: 0;
        overflow-x: hidden;
        overflow-y: scroll;
        -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes (webkit-only property)
    }

    .cloud-upload-fab,
    .cloud-standardlist__back-to-top {
        position: absolute;
        right: $poseidon-button-horizontal-padding * 2;
        bottom: $poseidon-button-vertical-padding * 2;
        z-index: 9;

        .pos-button {
            box-shadow: 0 0 $cloud-base-padding 0 $cloud-shadow;
        }
    }

    .cloud--has-footer {
        .cloud-upload-fab,
        .cloud-standardlist__back-to-top {
            bottom: $pos-footer-height + $poseidon-button-vertical-padding * 2;
        }
    }
}
