cloud-copy-to-clipboard {
    .pos-icon-item {
        color: $color-h3;
        fill: $color-h3;
    }

    textarea {
        position: fixed;
        left: -20px;
        width: 1px;
        height: 1px;
    }

    .cloud-copy-to-clipboard__textarea--hidden {
        display: none;
    }

    .link-text {
        display: block;
        height: 24px;
        text-align: center;
    }

    .success-text {
        display: flex;
        justify-content: center;
    }

    .pos-icon-item__label {
        padding-left: 5px;
    }

    .cloud-copy-to-clipboard__button-item {
        margin-right: -4px;
        margin-left: -4px;
    }
}
