/* posdoc
  name: Media Query Mixin
  description: This mixin offers a predefined set of media queries.
  data:
*/

/* posdoc
    pos-scss/tools/media-query.scss:
        '<code>
            <b>Usage:</b>@include media-query($screen-size)<br><br>
            $screen-size can take one of the following values:<br>
            <b>small</b><br>
            only for small screen<br>
            max-width: 767px<br><br>
            <b>medium</b><br>
            only for medium screens<br>
            min-width: 768px and max-width: 1199px<br><br>
            <b>medium-portrait</b><br>
             only for medium screens in portrait mode<br>
             min-width: 768px and max-width: 991px<br><br>
            <b>medium-landscape</b><br>
            only for medium screens in landscape mode<br>
            min-width: 992px and 1199px<br><br>
            <b>large</b><br>
            only for large screens<br>
            min-width: 1200px<br><br>
            <b>small-medium</b><br>
            small and medium screen sizes<br>
            max-width: 1199px<br><br>
            <b>small-medium-portrait</b><br>
            small and medium in portrait mode screen sizes<br>
            max-width: 991px<br><br>
            <b>medium-large</b><br>
            medium and large screen sizes<br>
            min-width: 768px<br><br>
            <b>medium-landscape-large</b><br>
            medium in landscape mode and large screen sizes<br>
            min-width: 992px<br><br>
        </code>'
*/

@mixin media-query($screen-size) {

    @if $screen-size == small {
        @media screen and (max-width: $breakpoint-small-max) {
            @content;
        }
    } @else if $screen-size == large {
        @media screen and (min-width: $breakpoint-large-min) {
            @content;
        }
    } @else if $screen-size == medium {
        @media screen and (min-width: $breakpoint-medium-min) and (max-width: $breakpoint-medium-max) {
            @content;
        }
    } @else if $screen-size == medium-portrait {
        @media screen and (min-width: $breakpoint-medium-min) and (max-width: $breakpoint-medium-portrait-max) {
            @content;
        }
    } @else if $screen-size == medium-landscape {
        @media screen and (min-width: $breakpoint-medium-landscape-min) and (max-width: $breakpoint-medium-max) {
            @content;
        }
    } @else if $screen-size == small-medium {
        @media screen and (max-width: $breakpoint-medium-max) {
            @content;
        }
    } @else if $screen-size == small-medium-portrait {
        @media screen and (max-width: $breakpoint-medium-portrait-max) {
            @content;
        }
    } @else if $screen-size == medium-large {
        @media screen and (min-width: $breakpoint-medium-min) {
            @content;
        }
    } @else if $screen-size == medium-landscape-large {
        @media screen and (min-width: $breakpoint-medium-landscape-min) {
            @content;
        }
    }

}
