cloud-sidebar {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding-left: $cloud-base-padding;
    background-color: $color-c-l94;

    & > * {
        flex-shrink: 0;
        padding-bottom: $cloud-base-padding;
    }

    & > *:first-child {
        height: $cloud-action-bar-height;
        padding-bottom: 0;
    }

    .cloud-sidebar__scroll {
        display: flex;
        flex-basis: 0;
        flex-direction: column;
        flex-grow: 1;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;  // sass-lint:disable-line no-vendor-prefixes (webkit-only property)

        cloud-folder-tree {
            flex-grow: 1;
            flex-shrink: 0;
        }

        .cloud-sidebar-infos-container {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }

    .cloud-sidebar-infos-container {
        display: table;
        width: 100%;
        padding-right: $cloud-base-padding;
    }

    .cloud-sidebar-infos {
        display: table-cell;
        vertical-align: bottom;
    }
}
