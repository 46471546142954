.nor-start-menu {
    cloud-hamburger {
        width: 100%;
        padding-left: 4px;
    }
}

nor-start-page-container {
    display: flex;
    flex-direction: column;
}