.clearfix {
    @include clearfix;
}

.center-block {
    @include center-block;
}

.pull-right {
    float: right !important;
}

.pull-left {
    float: left !important;
}

// Toggling content
// -------------------------
.show {
    display: block !important;
}

.invisible {
    visibility: hidden;
}

.text-hide {
    @include text-hide;
}

// Hide from screenreaders and browsers
//
// Credit: HTML5 Boilerplate
.hidden {
    display: none !important;
}

// For Affix plugin
// -------------------------
.affix {
    position: fixed;
}

.u-scroll-vertical {
    overflow-y: scroll;

    -webkit-overflow-scrolling: touch;
}
