$cloud-tile-filetype-size: 128px;

cloud-file-view {
    display: flex;
    flex-direction: column;

    cloud-scroll-area {
        flex-grow: 1;
    }

    .cloud-file-view__container {
        position: relative;
        max-width: 320px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: $cloud-base-padding * 2;
        padding-top: $cloud-base-padding * 6;
        padding-right: $cloud-base-padding * 2;
        padding-bottom: $cloud-base-padding * 4;
        padding-left: $cloud-base-padding * 2;
        background-color: $color-d;
        text-align: center;

        .cloud-file-view__icon-container {
            pos-svg-icon {
                width: $cloud-tile-filetype-size;
                height: $cloud-tile-filetype-size;
                margin-bottom: $cloud-base-padding * 3;
            }

            svg {
                width: $cloud-tile-filetype-size;
                height: $cloud-tile-filetype-size;
            }
        }
    }

    .cloud-file-view__image-container {
        width: 288px;
        height: 192px;
        margin-right: auto;
        margin-bottom: $cloud-base-padding * 3;
        margin-left: auto;
        border: 1px solid $color-b1-l82;
        border-radius: $cloud-base-025pct;
        background-repeat: no-repeat;
        background-size: cover;
        background-color: $color-b1-l94;
        animation: none;
        background-position: 0 0;

        &.cloud-file-view__image-container--thumbnail-loading {
            background-image: linear-gradient(
                90deg,
                rgba($color-d, 0),
                rgba($color-d, 0.2),
                rgba($color-d, 0)
            );
            background-position: left -250px top 0;
            animation: cloud-file-view-image-container-thumbnail-loading-animation 2s ease infinite;

            @keyframes cloud-file-view-image-container-thumbnail-loading-animation {
                to {
                    background-position: right -250px top 0;
                }
            }
        }
    }

    .cloud-file-view__file-container {
        margin-bottom: $cloud-base-padding * 2;
        border-radius: $cloud-base-025pct;
    }

    .cloud-file-view__video-play-icon {
        width: $cloud-base-200pct;
        height: 100%;
    }

    // sass-lint:disable no-vendor-prefixes
    @media (-moz-touch-enabled: 0), (pointer: fine) {
        .cloud-file-view__file-container:hover {
            background-color: $color-b-l82;
            cursor: pointer;
        }

        .cloud-file-view__image-container:hover {
            cursor: pointer;
        }

        .cloud-file-view__icon-container:hover {
            background-color: $color-b-l82;
            cursor: pointer;
        }
    }

    .cloud-file-view__name {
        color: $color-c;
        font-size: $cloud-base-layout-font;
        line-height: $cloud-base-150pct;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .cloud-file-view__size {
        margin-bottom: $cloud-base-padding;
        color: $color-c-l50;
        font-size: $cloud-font-size-small;
        line-height: $cloud-base-125pct;
    }

    .cloud-file-view__button {
        display: inline-block;
        width: 100%;
        height: 100%;
        margin-top: $cloud-base-padding;
    }

    .cloud-file-view__malware-icon {
        position: absolute;
        padding: 8px;
        top: 48px;
        right: 16px;
    }
}
