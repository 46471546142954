cloud-sidebar-footer {
    display: flex;
    flex-wrap: wrap;
    font-size: 10px;
    line-height: 14px;
    padding: $cloud-base-padding 0;
    gap: $cloud-base-padding;

    a {
        color: $color-c;

        &:first-of-type {
            margin-right: auto;
        }

        &:hover {
            color: $color-c;
        }
    }
}
