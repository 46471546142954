cloud-help-panel {
    display: flex;
    flex-direction: column;

    cloud-scroll-area {
        flex-grow: 1;
    }

    .cloud-help-panel__content {
        padding: 12px 24px;
        font-size: $cloud-font-size-medium;
        display: block;
    }

    .cloud-help-panel__headline {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 8px;
        color: $color-b1;
        font-family: $font-family-header;
        font-weight: $font-weight-header;
    }

    .cloud-help-panel__headline--gray {
        color: $color-c-l15;
    }

    .cloud-help-panel__image {
        width: 304px;
        align-self: center;
    }

    .cloud-help-panel__text {
        margin-bottom: 8px;
    }

    .cloud-help-panel__text--small {
        font-size: 10px;
        line-height: 14px;
        color: $color-c-l50;
        margin-bottom: 4px;
        margin-top: 8px;
    }

    .cloud-help-panel__block {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 304px;
        margin-right: 16px;
        margin-top: 24px;
    }

    .cloud-help-panel__link {
        display: flex;
        align-items: center;

        .pos-svg-icon {
            fill: $color-cloud-default-pdf;
            width: 32px;
            height: 32px;
        }
    }

    .cloud-help-panel__container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 32px;
        max-width: 1008px;
    }

    .cloud-help-panel__container-separator {
        display: none;
    }

    hr {
        border-top: 1px solid $color-c-l82;
    }

    @media (max-width: 687px) {
        .cloud-help-panel__container {
            flex-direction: column;
        }

        .cloud-help-panel__block {
            margin-right: 0;
            max-width: 100%;
            margin-top: 32px;
        }

        .cloud-help-panel__container-separator {
            display: block;
            width: 100%;
            border-top: 1px solid $color-c-l82;
        }
    }
}
