cloud-file-uploader-input {
    cursor: pointer;

    input {
        width: 330px;
        float: left;
    }

    button {
        font-weight: bold;
    }
}
