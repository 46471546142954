cloud-standard-list {
    display: flex;
    flex-direction: column;

    cloud-tool-bar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    cloud-scroll-area {
        flex-grow: 1;
    }

    .cloud-folder__empty {
        text-align: center;
        height: 100%;
        display: flex;
        padding: 0;
        box-sizing: border-box;

        .cloud-placeholder__wrapper {
            display: flex;
            flex: 1;
        }

        .cloud-placeholder__content {
            max-width: 100%;
            width: 100%;
            height: 100%;
        }

        nor-start-page-container {
            height: 100%;
            padding: 0;
        }
    }


    pos-spinner {
        margin: auto;
    }

    .cloud-standardlist__spinner--bottom {
        margin-top: -30px;
    }

    .cloud-scroll-area > div:has(nor-start-page) {
        height: 100%;
    }
}
