cloud-timeline {
    display: flex;
    flex-direction: column;

    cloud-tool-bar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    cloud-scroll-area {
        flex-grow: 1;
    }
}
