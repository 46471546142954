// Make the div behave like a button
.pos-button-group {
    display: inline-flex;
}

.pos-button-group .pos-button + .pos-button {
    border-radius: 0;
}

.pos-button-group .pos-button:first-child {
    @include border-left-radius($border-radius-base);

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.pos-button-group .pos-button:last-child {
    @include border-right-radius($border-radius-base);
    border-right: 0 solid transparent;
}

/****************************
 DEPRECATED
 ************************* */

.pos-button-group--vertical {
    display: inline-block;
}

// Justified button groups
// ----------------------

.pos-button-group--justified {
    display: table;
    width: 100%;
    border-collapse: separate;
    table-layout: fixed;
}

.pos-button-group--justified > .pos-button {
    display: table-cell;
    width: 1%;
}
