// Sizing shortcuts

@mixin size($width, $height) {
    width: $width;
    height: $height;
}

@mixin square($size) {
    @include size($size, $size);
}
