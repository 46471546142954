.cloud-action-bar {
    display: flex;
    align-items: center;
    height: $cloud-action-bar-height;
    padding-right: $cloud-base-padding;
    padding-left: $cloud-base-padding * 0.5;
    white-space: nowrap;

    .cloud-action-bar__left-area {
        flex-grow: 1;
        overflow: hidden;
    }

    .cloud-action-bar__right-area {
        & > * {
            margin-left: $cloud-base-padding * 0.5;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    .btn {
        font-size: inherit;
        padding: $cloud-base-padding * 0.5 $cloud-base-padding;

        .pos-button__wrapper {
            line-height: $cloud-base-150pct;
        }
    }

    .cloud-action-bar__list {
        display: flex;
        flex-wrap: nowrap;
        margin: 0;
        padding: 0;

        .cloud-action-bar__list-item {
            flex-grow: 0;
            flex-shrink: 0;
            margin-right: $cloud-base-padding * 0.5;
            padding: 0;
            list-style: none;

            &.cloud-action-bar__list-item--hidden {
                visibility: hidden;
            }

            &.cloud-action-bar__list-item--empty {
                width: 0;
                margin: 0;
                overflow: hidden;
                visibility: hidden;
            }
        }

        .pos-input-checkbox {
            padding-left: $cloud-base-padding * 1.5;

            label {
                margin-top: 1px;
                padding: $cloud-base-padding * 0.33;
            }

            label > i,
            input:checked + i {
                border-color: $color-d;
            }
        }
    }

    .cloud-action-bar__counter {
        padding-top: $cloud-base-padding;
        color: $color-d;
        line-height: $cloud-base-100pct;
    }

    .cloud-action-bar__counter--detailview {
        padding-left: $cloud-base-padding;
    }

    .cloud-action-bar__badge {
        margin-left: 5px;
        padding: 0 5px;
        border-radius: 2px;
        background-color: $color-a;
        color: $color-f;
        font-size: 10px;
        text-transform: uppercase;
    }

    &.cloud-action-bar--inverted {
        background-color: $color-c-l15;

        .pos-button--link {
            @include button-variant($color-d, transparent, transparent);
            @include button-selected($color-d, $color-c, $color-c);
        }
    }
}

@media (max-width: $breakpoint-extra-small-max) {
    cloud-action-bar {
        border-top: 0;
        border-bottom: 0;
    }

    .cloud-action-bar {
        .cloud-action-bar__left-area {
            flex-basis: 0;
            flex-grow: 0;
        }

        .cloud-action-bar__right-area {
            flex-grow: 1;
        }

        &.cloud-action-bar--inverted {
            .cloud-action-bar__left-area {
                flex-grow: 1;
            }

            .cloud-action-bar__right-area {
                flex-grow: 0;
            }
        }
    }
}
