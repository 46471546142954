cloud-share-info-message {

    .cloud-share-info__message {
        display: block;
        width: 100%;
        margin: 0;
        padding: $cloud-base-padding;
    }

    @media (max-width: $breakpoint-small-max) {
        .cloud-share-info__message {
            padding: $cloud-base-padding 0;
        }
    }

    @media (max-width: $breakpoint-small-min) {
        .cloud-share-info__message {
            padding: 0;
        }
    }

    .cloud-share-info-message__placeholder {
        margin-top: $cloud-base-padding;
        padding: 100px 20px;
        background-color: $color-b-l94;

        div {
            margin-bottom: $cloud-base-padding;
        }
    }

    .pos-message__details {
        margin-top: 3px;
    }
}
