cloud-not-found {
    .cloud-not-found__placeholder {
        margin-top: $cloud-base-padding;
        padding: 100px 20px;
        background-color: $color-b-l94;

        div {
            margin-bottom: $cloud-base-padding;
        }
    }
}
