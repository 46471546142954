// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border, $background-active: null, $color-active: null, $background-hover: null) {
    border-color: $border;
    background-color: $background;
    color: $color;
    fill: $color;

    &:focus,
    &.focus {
        border-color: $border;
        background-color: $background;
        color: $color;
    }

    &:hover {
        border-color: mix($black, $border, 20%);
        @if($background-hover) {
            background-color: $background-hover;
        } @else {
            background-color: mix($black, $background, 20%);
        }
        color: $color;
        text-decoration: none;
    }


    @media (-moz-touch-enabled: 1), (pointer: coarse) {
        &:hover {
            border-color: $border;
            background-color: $background;
            color: $color;
            text-decoration: unset;
        }
    }

    &:active,
    &.active {
        border-color: $border;
        @if($background-active) {
            background-color: $background-active;
        } @else {
            background-color: mix($black, $background, 10%);
        }
        @if($color-active) {
            color: $color-active;
            fill: $color-active;
        } @else {
            color: $color;
        }

        &:hover,
        &:focus,
        &.focus {
            border-color: $border;
            @if($background-active) {
                background-color: $background-active;
            } @else {
                background-color: mix($black, $background, 10%);
            }
            @if($color-active) {
                color: $color-active;
                fill: $color-active;
            } @else {
                color: $color;
            }
        }
    }

    &.disabled,
    &[disabled],
    fieldset[disabled] & {
        &:hover {
            border-color: $border;
            background-color: $background;
        }

        &:focus,
        &.focus {
            border-color: $border;
            background-color: $background;
        }
    }

}

@mixin button-selected($color, $background, $border) {
    &.pos-button--selected {
        border-color: mix($black, $border, 10%);
        background-color: mix($black, $background, 10%);
        color: $color;
        fill: $color;

        &:hover {
            border-color: mix($black, $border, 20%);
            background-color: mix($black, $background, 20%);
            color: $color;
            text-decoration: none;
        }

        @media (-moz-touch-enabled: 1), (pointer: coarse) {
            &:hover {
                border-color: mix($black, $border, 10%);
                background-color: mix($black, $background, 10%);
                color: $color;
                fill: $color;
            }
        }
    }
}

// Button sizes
@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $line-height, $border-radius) {
    padding: $padding-vertical $padding-horizontal;
    border-radius: $border-radius;
    font-size: $font-size;
    line-height: $line-height;
}
