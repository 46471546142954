cloud-upload-dialog {
    width: 375px;

    $cloud-upload-bgcancel: #fefbf0;
    $cloud-upload-bgerror: #fbf0f3;
    $cloud-upload-bgsuccess: #f5fbf2;
    $cloud-upload-row-border: #dadada;
    $base-content-height: 29px;

    .cloud-upload-more {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        margin-top: 10px;
        border: 5px $cloud-upload-row-border dashed;
        padding: $cloud-base-padding;
        text-align: center;
    }

    .upload-icon {
        text-align: center;

        .pos-svg-icon {
            margin-bottom: 36px;
        }

        .brand-color {
            fill: $color-b;
        }
    }

    .cloud-upload-paused-container {
        .pos-svg-icon {
            margin-bottom: 12px;
            fill: $message-warning-color;
        }

        .cloud-headline {
            margin-bottom: 24px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .cloud-upload-progress-bar {
        display: inline-block;
        width: 100%;
        margin-bottom: 5px;

        .cloud-upload-dialog-error {
            display: block;
            color: $color-h2;
            font-size: $cloud-font-size-small;
        }

        .cloud-upload-dialog-target {
            padding-top: $cloud-base-padding;
            font-size: $cloud-font-size-medium;
        }

        .cloud-upload-progress-total {
            min-height: 50px;
            font-size: $cloud-font-size-large;
            text-align: center;
        }

        .cloud-upload-progress-status {
            padding: $cloud-base-padding 0;
            font-size: $cloud-font-size-small;

            div {
                float: right;
            }

            div:first-child {
                float: left;
            }
        }

        .cloud-upload-loading::after {
            @include mixin-animation(cloud-upload-loading-ellipsis, 900ms, steps(4), infinite, alternate);
            display: inline-block;
            width: 0;
            content: '\2026'; /* ascii code for the ellipsis character */
            overflow: hidden;
            vertical-align: bottom;
        }

        @keyframes cloud-upload-loading-ellipsis {
            to {
                width: $cloud-font-size-large;
            }
        }

        .pos-progressbar,
        .pos-progressbar-indicator {
            height: $cloud-font-size-large;
        }

        .pos-progressbar.progressbar-status {
            .pos-progressbar-indicator {
                background-color: $color-b;
            }
        }

        .pos-progressbar.progressbar-complete {
            background-color: $color-h2;

            .pos-progressbar-indicator {
                background-color: $color-h3;
            }
        }
    }

    .pos-table.cloud-table-upload {
        height: 90px;
        margin-bottom: 0;
        overflow-y: auto;
        overflow-x: hidden;

        tbody {
            border-top: 1px solid $cloud-upload-row-border;

            &.file-upload-cancel {
                background-color: $cloud-upload-bgcancel;
            }
        }

        tr {
            &.pos-table__row {
                height: $cloud-base-275pct;
                border-top: 0;
            }

            &.pos-table__row:hover {
                background: inherit;
                cursor: auto;
            }

            &.cloud-table-row-upload-messages {
                height: $cloud-base-150pct;

                td.cloud-table-text-overflow-wrapper {
                    div {
                        line-height: $cloud-base-150pct;
                    }
                }
            }

            td {
                width: 100%;
                padding-right: $cloud-base-padding;
                line-height: $cloud-base-200pct;

                &:first-child {
                    position: relative;
                    width: 100%;
                    padding: 0 $cloud-base-025pct * 0.5;
                    word-wrap: normal;
                }

                &.cloud-table-align-right {
                    padding-right: 2 * $cloud-base-padding;
                    color: $color-c-l50;
                    font-size: $cloud-font-size-small;
                    text-align: right;
                    white-space: nowrap;
                }

                &.cloud-table-text-overflow-wrapper {
                    position: relative;

                    .cloud-table-text-overflow {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        padding: 0 2 * $cloud-base-padding;
                        line-height: $cloud-base-275pct;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }
                }

                .pos-svg-icon {
                    display: inline-flex;
                }

                &.line-separator {
                    height: 0;
                    padding: 0;
                    border-top: 1px solid $color-c-l82;

                    .n7-progressbar,
                    .n7-progressbar-empty,
                    .n7-progressbar-status {
                        height: $base-content-height * 0.25;
                    }
                }

                .cloud-function-icon {
                    .pos-svg-icon {
                        display: flex;
                        float: right;
                    }
                }

                .cloud-upload-icon-cancel {
                    .pos-svg-icon {
                        cursor: pointer;
                        fill: $color-c-l50;
                    }
                }

                ul.cloud-upload-list {
                    margin: 0;
                    padding: 0;
                    list-style-type: none;

                }

                .cloud-info-icon  {
                    float: right;
                }

                & .cloud-table-row-upload-messages {
                    height: $cloud-base-150pct;

                    div {
                        line-height: 20px;
                    }
                }
            }


            &.cloud-file-upload-progressbar {
                height: auto;

                .pos-progressbar {
                    .pos-progressbar-indicator {
                        background-color: $color-b;
                    }
                }
            }
        }
    }

    .cloud-file-upload--text-ellipsis {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .cloud-file-upload-details {
        ul {
            height: 85px;
            margin: 0;
            padding: 0;
            overflow: auto;

            li {
                padding: 5px 0;
                border-top: 1px $color-c-l82 solid;
                list-style: none;
            }

            li:last-child {
                border-bottom: 1px $color-c-l82 solid;
            }
        }

        .pos-svg-icon {
            fill: $color-b;
        }
    }

    .cloud-file-upload-sharenow {
        display: flex;
        flex-wrap: wrap;
        padding-top: 15px;

        .cloud-file-upload--text-ellipsis {
            max-width: 230px;
        }

        .cloud-file-upload--text-bold {
            font-weight: bold;
        }

        .pos-button--link {
            &:hover {
                background-color: transparent;
            }
        }

        .cloud-file-upload-text-container {
            margin-right: $cloud-base-padding;
        }

        .cloud-file-upload-inner-text-container {
            display: inline-flex;
        }
    }
}

.cloud-upload-more:hover,
.cloud--dnd-over .cloud-upload-more {
    border: 5px $color-link dashed;
    cursor: pointer;
}

