cloud-pin-input {
    padding-top: 16px;

    ul.cloud-pin-input__list-errors {
        min-height: $poseidon-svg-dimension;
        margin: 8px 0 16px;
        padding-left: 4px;
        list-style-type: none;
    }

    .pos-form-message--error {
        align-items: start;
    }
}
