.pos-header {
    display: flex;
    align-items: center;
    padding: 0 16px;
    border-top: 4px solid $color-n;
    background-color: $color-o;
    color: $color-v;
}

.pos-header--small {
    padding: 0 8px;

    .pos-header__content {
        height: 44px;
    }
}

.pos-header__content {
    height: 52px;
    display: flex;
    align-items: center;
}
