cloud-device-upload-dialog {
    display: block;
    text-align: center;

    .brand-color {
        fill: $color-b;
    }

    .upload-icon {
        text-align: center;

        .pos-svg-icon {
            margin-bottom: 36px;
        }
    }

    .cloud-device-upload__info-icon {
        cursor: pointer;
    }

    .cloud-device-upload__info {
        display: block;
        width: 100%;
        margin-top: 2 * $cloud-base-padding;
        margin-bottom: 0;
    }

    .cloud-device-upload--paused {
        .pos-svg-icon {
            margin-bottom: 12px;
        }

        svg {
            fill: $message-warning-color;
        }

        .cloud-headline {
            margin-bottom: 24px;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .cloud-upload-progress-total {
        min-height: 50px;
        font-size: $cloud-font-size-large;
        text-align: center;

        .cloud-upload-dialog-target {
            padding-top: $cloud-base-padding;
            font-size: $cloud-font-size-medium;
        }
    }

    .cloud-upload-dialog-error {
        display: block;
        color: $color-h2;
        font-size: $cloud-font-size-small;
    }

    .pos-progressbar,
    .pos-progressbar-indicator {
        height: $cloud-font-size-large;
    }

    .pos-progressbar.progressbar-status {
        .pos-progressbar-indicator {
            background-color: $color-b;
        }
    }

    .pos-progressbar.progressbar-complete {
        background-color: $color-h2;

        .pos-progressbar-indicator {
            background-color: $color-h3;
        }
    }
}
