.pos-dialog-modal-layer {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
}

.pos-dialog-modal-layer__backdrop {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $modal-layer-background-color;
    cursor: pointer;
    z-index: 10;
}

.pos-dialog-modal-layer__wrapper {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
    z-index: 20;
    pointer-events: none;
}

// reset the pointer events for subelements
.pos-dialog-modal-layer__wrapper > * {
    pointer-events: auto;
}

.pos-dialog-modal-layer--visible {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
