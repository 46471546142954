.pos-modal-layer {
    display: none;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: $modal-layer-background-color;
    cursor: pointer;
    z-index: 3;
}

.pos-modal-layer--visible {
    display: block;
}
