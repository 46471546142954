.pos-progressbar {
    display: block;
    @include size(100%, 8px);
    margin-top: 8px;
    background-color: $color-c-l82;
    border-radius: 4px;
}

.pos-progressbar-indicator {
    @include size(0, 100%);
    border-radius: 4px 0 0 4px;
}

.pos-progressbar--medium {
    @include size(100%, 8px);
}

.pos-progressbar--large {
    @include size(100%, 16px);
}

.pos-progressbar--small {
    @include size(100%, 4px);
}

.pos-progressbar .pos-progressbar-indicator,
.pos-progressbar--default .pos-progressbar-indicator {
    background-color: $color-b1;
}

.pos-progressbar--error .pos-progressbar-indicator {
    background-color: $color-h2;
}

.pos-progressbar--warning .pos-progressbar-indicator {
    background-color: $color-h1;
}

.pos-progressbar--confirm .pos-progressbar-indicator {
    background-color: $color-h3;
}
