cloud-create-folder-dialog {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .cloud-create-folder__icon {
        align-self: center;
        margin-bottom: $cloud-base-100pct;
        fill: $color-b1;
    }

    .cloud-create-folder__text {
        max-width: 285px;
        margin: 0 auto 15px;
        text-align: center;
    }
}
