.cloud-file-name-input {
    display: flex;
    flex-grow: 1;
}

.cloud-file-name-input__name {
    flex-grow: 1;

    .cloud-file-name-input__name-input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        // sass-lint:disable no-vendor-prefixes

        &::-ms-clear {
            display: none;
        }
    }

    &.pos-input--focus {
        .cloud-file-name-input__name-input {
            border-color: $color-b;
        }
    }
}

.cloud-file-name-input__extension {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    padding: 0 8px;

    @include border-right-radius($border-radius-base);
    border: 1px solid $color-c-l70;
    border-left: 0;
    fill: $color-c-l50;

    .cloud-file-name-input__extension-text {
        user-select: none;
    }

    .cloud-file-name-input__extension-arrow {
        margin-left: 8px;
        width: 20px;
        height: 20px;

        &.cloud-file-name-input__extension-arrow--active {
            fill: $color-b;
        }
    }

    &:hover {
        fill: $color-b;
    }

    &.pos-input--focus {
        border-color: $color-b;
    }

    &.pos-input--error {
        border-color: $color-h2;
    }

    &.pos-input--clickable {
        cursor: pointer;
    }
}
