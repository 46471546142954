@import 'pos-message-vars';

.pos-modal-wrapper {
    z-index: 4;
}

.pos-message-modal {
    align-self: center;
    min-width: $message-min-width;
    max-width: $message-max-width;
    margin: auto;
}

.pos-message-modal__wrapper {
    position: relative;
    border: 1px solid $modal-content-border-color;
    border-radius: $border-radius-base;
    // Remove focus outline from opened modal
    outline: 0;
    @include box-shadow(0 5px 15px rgba(0, 0, 0, 0.5));
    background-clip: padding-box;
    background-color: $color-d;
}

@include media-query(medium-large) {
    .pos-message-modal__wrapper {
        @include box-shadow(0 3px 9px rgba(0, 0, 0, 0.5));
    }
}

.pos-message-modal__body {
    padding: 16px 16px 32px;
}

.pos-message-modal__icon {
    margin-bottom: 12px;
}

.pos-message-modal__heading {
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 24px;
}

.pos-message-modal__heading,
.pos-message-modal__details {
    text-align: center;
}

.pos-message-modal__footer {
    padding: 8px;
    border-top: 1px solid $modal-footer-border-color;
    border-radius: 0 0 $border-radius-base $border-radius-base;
    background-color: $color-c-l94;
    text-align: right; // right align buttons
    @include clearfix; // clear it in case folks use .pull-* classes on buttons
}

.pos-message-modal__action-button + .pos-message-modal__action-button {
    margin-left: 8px;
}
