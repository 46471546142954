cloud-prepare-download-dialog {
    text-align: center;

    .cloud-prepare-download__text {
        max-width: 285px;
        margin: 0 auto 15px;
        text-align: center;
    }

    .pos-spinner {
        margin: 0 auto;
    }
}
