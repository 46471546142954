
::-webkit-input-placeholder {
    color: $color-c-l30;
}

:-moz-placeholder {
    color: $color-c-l30;
}

::-moz-placeholder {
    color: $color-c-l30;
}

:-ms-input-placeholder {
    color: $color-c-l30;
}

fieldset {
    min-width: 0;
    margin: 0;
    padding: 0;
    border: 0;
}

.pos-form-wrapper {
    margin-bottom: 16px;
}

.pos-input {
    display: block;
    position: relative;
}

.pos-input-dob {
    display: inline-flex;
    align-items: center;
}

.pos-input input,
.pos-input select {
    width: 100%;
    height: 32px;
    padding: 0 8px;
    border: 1px solid $color-c-l70;
    border-radius: $border-radius-base;
    background-color: $color-d;
    color: $color-c-l15;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
    box-shadow: none;
}

.pos-input select {
    padding-right: 29px;
    background: $white url('data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2020%2020%22%3E%3Cpath%20d%3D%22M10%2014.5c-.3%200-.5-.1-.7-.3l-7-7c-.4-.4-.4-1%200-1.4.4-.4%201-.4%201.4%200l6.3%206.3%206.3-6.3c.4-.4%201-.4%201.4%200%20.4.4.4%201%200%201.4l-7%207c-.2.2-.5.3-.7.3z%22%2F%3E%3C%2Fsvg%3E') right 7px center no-repeat;
    background-size: 12px 12px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.pos-input select::-ms-expand {
    display: none;
}

select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
}

input[type='search'] {
    @include box-sizing(border-box);
    -webkit-appearance: none;
}

input[type='file'] {
    display: block;
}

.pos-input .pos-input--password,
.pos-input .pos-input--search {
    padding-right: 45px;
}

.pos-input--password::-ms-clear {
    width: 0;
    height: 0;
}

.pos-input--password::-ms-reveal {
    display: none;
}

.pos-input:hover input,
.pos-input input:hover,
.pos-input input:active,
.pos-input input:focus,
.pos-input select:hover,
.pos-input select:active,
.pos-input select:focus,
.pos-input-radio__label:hover .pos-input-radio__border,
.pos-input-radio__input:focus + .pos-input-radio__border,
.pos-input-checkbox__label:hover .pos-input-checkbox__border,
.pos-input-checkbox__input:focus + .pos-input-checkbox__border {
    border-color: $color-b1;
}

.pos-input input:disabled,
.pos-input select:disabled {
    border-color: $color-c-l70;
    background-color: $color-c-l94;
    color: $color-c-l50;
}

.pos-input--error input,
.pos-input--error input:focus,
.pos-input--error input:hover,
.pos-input--error select,
.pos-input--error select:focus,
.pos-input--error select:hover,
.pos-input-radio--error .pos-input-radio__border,
.pos-input-checkbox--error .pos-input-checkbox__border {
    border-color: $color-h2;
}

.pos-input-radio--error:hover .pos-input-radio__border,
.pos-input-checkbox--error:hover .pos-input-checkbox__border {
    border-color: $color-b;
}

.pos-input-smalltext {
    font-size: 12px;
}

.pos-label {
    color: $color-c-l15;
    font-size: 12px;
    font-weight: normal;
    line-height: 18px;
    white-space: nowrap;
}


.pos-label--inline {
    align-self: center;
    margin-right: 16px;
    margin-bottom: 0;
}

.pos-label--block {
    display: block;
    margin-bottom: 4px;
}

.pos-input .pos-dob {
    width: 64px;
    margin-right: 16px;
}

.pos-input .pos-dob--dd,
.pos-input .pos-dob--mm {
    width: 44px;
    margin-right: 8px;
}

.pos-input select > option {
    padding: 14px 0 13px 16px;
}

.pos-input select > option:hover {
    background-color: $color-b;
}

.pos-input select:focus::-ms-value {
    background-color: $color-d;
    color: $color-c;
}

.pos-hint-text {
    margin-bottom: 8px;
    color: $color-h2;
    white-space: nowrap;
}

.pos-hint-text--ok {
    color: $color-c-l50;
}

.pos-input-icon {
    position: absolute;
    top: 0;
    right: 8px;
    bottom: 0;
    left: auto;
    width: 20px;
    height: 20px;
    margin: auto 0;
    z-index: 2;
    fill: $color-c-l30;
}

.pos-input-toggle.pos-input-toggle--active,
.pos-input-toggle:hover {
    fill: $color-b;
}

.pos-input-toggle.pos-input-toggle--active:hover,
.pos-input-toggle {
    fill: $color-c-l30;
}

.pos-form-message--content-right .pos-form-message__wrapper {
    justify-content: right;
}

.pos-form-message__wrapper {
    display: flex;
    min-height: 24px;
    margin-top: 8px;
    margin-bottom: 16px;
}

.pos-form-message--negative-top-margin .pos-form-message__wrapper {
    margin-top: -8px;
}

.pos-form-message .pos-svg-icon {
    width: 24px;
    height: 24px;
    padding: 2px;
}

.pos-form-message--success {
    color: $color-h3;
    fill: $color-h3;
}

/****************************
 DEPRECATED
 ************************* */

// TODO remove deprecated code
.pos-form-message--failure {
    color: $color-h2;
    fill: $color-h2;
}

.pos-form-message--error {
    color: $color-h2;
    fill: $color-h2;
}

.pos-form-message--warning {
    color: $color-c;
    fill: $color-h1;
}

.pos-form-message--info {
    color: $color-c;
    fill: $color-h4;
}

.pos-form-message-text {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    align-self: center;
    padding-left: 4px;
}

.pos-input-radio,
.pos-input-checkbox {
    margin-right: 8px;
    margin-left: -8px;
}

/*Custom radio button*/

.pos-input-radio__label {
    display: inline-flex;
    position: relative;
    margin: 0;
    padding: 8px;
    font-weight: normal;
    cursor: pointer;
}

.pos-input-radio__label--disabled {
    cursor: default;
}

.pos-input-radio__border {
    position: relative;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border: 1px solid $color-c-l50;
    border-radius: 100%;
    background-color: $color-d;
    font-style: normal;
}

.pos-input-radio__checker {
    display: block;
    width: 10px;
    height: 10px;
    margin: 4px 0 0 4px;
    border: 1px solid transparent;
    border-radius: 100%;
    background-color: transparent;
}

.pos-input-radio__labeltext,
.pos-input-checkbox__labeltext {
    flex-basis: auto;
    flex-grow: 1;
    flex-shrink: 1;
    padding: 0 8px 0 6px;
    color: $color-c;
    font-size: 14px;
    line-height: 22px;
}


.pos-input-radio__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.pos-input-radio__input:checked + .pos-input-radio__border > .pos-input-radio__checker {
    border-color: $color-b1;
    background-color: $color-b1;
}

.pos-input-radio--error .pos-input-radio__input:checked + .pos-input-radio__border > .pos-input-radio__checker {
    border-color: $color-h2;
    background-color: $color-h2;
}

.pos-input-radio__label--disabled .pos-input-radio__border,
.pos-input-radio__label--disabled:hover .pos-input-radio__border {
    border-color: $color-c-l82;
}

.pos-input-radio__label--disabled .pos-input-radio__labeltext {
    color: $color-c-l82;
}

.pos-input-radio__label--disabled .pos-input-radio__input:checked + .pos-input-radio__border .pos-input-radio__checker {
    border-color: $color-c-l82;
    background-color: $color-c-l82;
}

/*Custom checkbox */

.pos-input-checkbox__label {
    display: inline-flex;
    position: relative;
    margin: 0;
    padding: 8px;
    font-weight: normal;
    cursor: pointer;
}

.pos-input-checkbox__label--disabled {
    cursor: default;
}

.pos-input-checkbox__border {
    display: flex;
    flex-shrink: 0;
    justify-content: center;
    width: 20px;
    height: 20px;
    border: 1px solid $color-c-l50;
    border-radius: 3px;
    background-color: $color-d;
}

.pos-input-checkbox__checker {
    display: none;
}

.pos-input-checkbox__input {
    position: absolute;
    opacity: 0;
    z-index: -1;
}

.pos-input-checkbox__input:checked + .pos-input-checkbox__border > .pos-input-checkbox__checker {
    display: block;
    width: 8px;
    height: 12px;
    margin-top: 1px;
    transform: rotate(45deg);
    background: linear-gradient(to bottom, transparent 9px, $color-b1 9px), linear-gradient(to right, transparent 5px, $color-b1 5px);
}

.pos-input-checkbox--error .pos-input-checkbox__input:checked + .pos-input-checkbox__border > .pos-input-checkbox__checker {
    background: linear-gradient(to bottom, transparent 9px, $color-h2 9px), linear-gradient(to right, transparent 5px, $color-h2 5px);
}

.pos-input-checkbox__label--disabled .pos-input-checkbox__border,
.pos-input-checkbox__label--disabled:hover .pos-input-checkbox__border {
    border-color: $color-c-l82;
}

.pos-input-checkbox__label--disabled .pos-input-checkbox__labeltext,
.pos-input-checkbox__label--disabled:hover .pos-input-checkbox__labeltext {
    color: $color-c-l82;
}

.pos-input-checkbox__label--disabled .pos-input-checkbox__input:checked + .pos-input-checkbox__border .pos-input-checkbox__checker {
    background: linear-gradient(to bottom, transparent 9px, $color-c-l82 9px), linear-gradient(to right, transparent 5px, $color-c-l82 5px);
}
