cloud-sort-menu {
    display: flex;
    align-items: center;
    margin-right: $cloud-base-padding;
}

// remove spacing for tablet and below: workaround untill POS-3751 is fixed
@media (max-width: $breakpoint-medium-max) {
    cloud-sort-menu {
        [pos-button].pos-button {
            .pos-svg-icon {
                margin: 0;
            }
        }
    }
}
