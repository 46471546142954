//
// Close icons
// --------------------------------------------------

.close {
    float: right;
    color: #000;
    font-size: ($font-size-base * 1.5);
    font-weight: bold;
    line-height: 1;

    text-shadow: bold;
    @include opacity(0.2);

    &:hover,
    &:focus {
        color: #000;
        text-decoration: none;
        cursor: pointer;
        @include opacity(0.5);
    }

    // [converter] extracted button& to button.close
}

// Additional properties for button version
// iOS requires the button element instead of an anchor tag.
// If you want the anchor version, it requires `href="#"`.
// See https://developer.mozilla.org/en-US/docs/Web/Events/click#Safari_Mobile
button.close {
    padding: 0;
    border: 0;
    background: transparent;
    cursor: pointer;
    appearance: none;
}

