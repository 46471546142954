// Exact reflection of LUX typography types as mixins

@mixin head-48 {
    font-size: 48px;
    line-height: 56px;
    font-family: $font-family-hero;
    font-weight: $font-weight-header;
    color: $font-color-header;
}

@mixin head-40 {
    font-size: 40px;
    line-height: 48px;
    font-family: $font-family-hero;
    font-weight: $font-weight-header;
    color: $font-color-header;
}

@mixin head-32 {
    font-size: 32px;
    line-height: 40px;
    font-family: $font-family-hero;
    font-weight: $font-weight-header;
    color: $font-color-header;
}

@mixin head-28 {
    font-size: 28px;
    line-height: 36px;
    font-family: $font-family-header;
    font-weight: $font-weight-header;
    color: $font-color-header;
}

@mixin head-24 {
    font-size: 24px;
    line-height: 32px;
    color: $font-color-header;
    font-family: $font-family-header;
    font-weight: $font-weight-header;
}

@mixin head-20 {
    font-size: 20px;
    line-height: 28px;
    font-weight: $font-weight-header;
    font-family: $font-family-header;
    color: $font-color-header;
}

@mixin head-16 {
    font-size: 16px;
    line-height: 24px;
    font-weight: $font-weight-header;
    font-family: $font-family-header;
    color: $font-color-header;
}

@mixin copy-16 {
    font-size: 16px;
    line-height: 24px;
    font-family: $font-family-base;
    color: $font-color-base;
}

@mixin copy-14 {
    font-size: 14px;
    line-height: 22px;
    font-family: $font-family-base;
    color: $font-color-base;
}

@mixin copy-12 {
    font-size: 12px;
    line-height: 18px;
    font-family: $font-family-base;
    color: $font-color-base;
}

@mixin copy-11 {
    font-size: 11px;
    line-height: 16px;
    font-family: $font-family-base;
    color: $font-color-base;
}

@mixin copy-10 {
    font-size: 10px;
    line-height: 14px;
    font-family: $font-family-base;
    color: $font-color-base;
}

@mixin copy-9 {
    font-size: 9px;
    line-height: 12px;
    font-family: $font-family-base;
    color: $font-color-base;
}

