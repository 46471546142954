cloud-drag-info {
    .cloud-drag-info-container {
        display: block;
        position: absolute;
        min-width: 144px;
        border-radius: $border-radius-base;
        background-color: $color-d;
        box-shadow: 0 0 $cloud-base-padding 0 $cloud-shadow;
        z-index: $cloud-zindex-drag-info;

        .pos-svg-icon {
            width: 24px;
            height: 24px;
            padding: $cloud-base-012pct;
            float: left;
            color: $color-h3;
            fill: $color-h3;
        }

        .cloud-drag-info-text {
            display: inline-block;
            min-width: 200px;
            padding-left: $cloud-base-padding;
            color: $black;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }

    .cloud-drag-info-container-inner {
        display: inline-block;
        position: relative;
        top: - $cloud-base-padding;
        left: - $cloud-base-padding;
        width: 100%;
        padding: 10px;
        border-radius: $border-radius-base;
        background-color: $color-d;
        box-shadow: 0 0 $cloud-base-padding 0 $cloud-shadow;
        visibility: visible;
    }
}
