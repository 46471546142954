cloud-filter-bar {
    display: flex;
    height: $cloud-breadcrumb-height;
    padding: 8px;

    cloud-filter-date {
        margin-right: 8px;

        span {
            flex-grow: 1;
        }
    }

    .pos-button {
        width: 88px;
        justify-content: center;
    }
}

@media (max-width: 650px) {
    cloud-filter-bar {
        width: 100%;
        justify-content: right;
    }
}


@media (max-width: $breakpoint-extra-small-max) {
    cloud-filter-bar {
        pos-button-group {
            flex-grow: 1;

            .pos-button {
                width: 100%;
            }
        }
    }
}
