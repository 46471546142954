.l-hidden-small {
    @include media-query(small) {
        display: none !important;
    }
}

.l-hidden-medium {
    @include media-query(medium) {
        display: none !important;
    }
}

.l-hidden-medium-portrait {
    @include media-query(medium-portrait) {
        display: none !important;
    }
}

.l-hidden-medium-landscape {
    @include media-query(medium-landscape) {
        display: none !important;
    }
}

.l-hidden-large {
    @include media-query(large) {
        display: none !important;
    }
}
