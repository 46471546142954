cloud-print-dialog {
    $cewe-color: #c30c15;
    $progress-text-item-width: 120px;
    $progress-circle-radius: 48px;
    display: block;
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;

    .cloud-print-dialog__progress-bar {
        display: flex;
        flex-direction: row;
        padding-right: ($progress-text-item-width - $progress-circle-radius) * 0.5;
        padding-left: ($progress-text-item-width - $progress-circle-radius) * 0.5;

        pos-svg-icon {
            fill: $color-b;
        }

        .cloud-print-dialog__step-circle {
            width: $progress-circle-radius;
            height: $progress-circle-radius;
            border-width: 2px;
            border-style: solid;
            border-radius: $progress-circle-radius;
        }

        .cloud-print-dialog__step-text {
            padding-top: 22%;
            color: $color-b;
            font-size: $font-size-large;
            font-weight: bold;
            text-align: center;
        }

        .cloud-print-dialog__line {
            position: relative;
            top: 24px;
            width: 50%;
            height: 0;
            border-width: 2px 0 0;
            border-style: solid;
            border-color: $color-c-l50;
        }

        .cloud-print-dialog__step--active {
            border-color: $color-b;
        }

        .cloud-print-dialog__step--cewe {
            border-color: $cewe-color;

            pos-svg-icon {
                margin-top: 6px;
                margin-left: 6px;
            }

            svg {
                fill: $cewe-color;
            }
        }

        .cloud-print-dialog__step-cewe-active {
            background-color: $cewe-color;

            svg {
                fill: $white;
            }
        }
    }

    .cloud-print-dialog__color--active {
        color: $color-b;
    }

    .cloud-spinner {
        margin: 0 auto;
    }

    .cloud-print-dialog__progress-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: $cloud-base-padding;
        color: $color-c-l50;
        text-align: center;

        .cloud-print-dialog__progress-text-item {
            width: $progress-text-item-width;
        }
    }

    .cloud-print-dialog__subtitle {
        padding-top: $cloud-base-padding * 3;
        padding-bottom: $cloud-base-padding * 3;
        font-weight: bold;
        text-align: center;
    }

    .cloud-print-dialog__text {
        height: 90px;
        padding-top: $cloud-base-padding;
        padding-bottom: $cloud-base-padding;
        text-align: center;
    }

    .cloud-print-dialog__grid-container {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        input {
            display: none;
        }

        .cloud-print-dialog__grid-item {
            display: inline-block;
            margin-bottom: $cloud-base-padding;
            padding: $cloud-base-padding;
            border-radius: $border-radius-base;

            &:hover {
                background-color: $color-b-l82;
            }

            .cloud-print-dialog__grid-text {
                padding-top: $cloud-base-padding;
            }
        }

        input:checked + .cloud-print-dialog__grid-item {
            background-color: $color-b;
        }

        input:checked + .cloud-print-dialog__grid-item .cloud-print-dialog__grid-text {
            background-color: $color-b;
            color: $color-d;
        }

        .cloud-print-dialog__grid-image {
            width: 144px;
            height: 100px;
            background-size: contain;
        }
    }

    .cloud-print-dialog__frosted-glass {
        opacity: 0.2;
        pointer-events: none;
        user-select: none;
    }
}
