cloud-popup {
    $cloud-popup-blocker-background-color: transparent;

    .cloud-popup__list {
        display: block;
        position: absolute;
        margin: 0;
        padding: ($cloud-base-padding * 0.5) 0;
        border-radius: $border-radius-base;
        list-style: none;
        visibility: hidden;
        z-index: $cloud-zindex-popup;

        @include box-shadow(0 0 $cloud-base-padding $cloud-shadow);

        &.cloud-popup__list--visible {
            visibility: visible;
        }

        &.cloud-popup__list--full-height {
            bottom: 0;
            overflow: auto;
        }
    }

    .cloud-popup-list__blog-icon {
        position: inherit;
        top: $cloud-base-025pct;
        left: $cloud-base-012pct;
        width: $cloud-base-150pct;
        height: $cloud-base-150pct;

        svg {
            fill: $color-d;
        }
    }

    .cloud-popup__list--menu {
        width: $cloud-menu-width;
        border: 1px solid $color-c-l82;
        background: $color-d;

        .cloud-popup__entry {
            padding: 10px 12px;
            color: $color-c-l15;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 100%;
            display: flex;

            &:hover {
                background-color: $color-b1-l82;
                cursor: pointer;
            }

            &.cloud-popup__entry-name--selected {
                background-color: $color-b1-l82;
            }

            .cloud-popup__entry-name {
                font-size: $cloud-font-size-medium;
                line-height: $cloud-base-150pct;
                text-align: left;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
            }

            &.cloud-popup__entry--separator {
                padding: $cloud-base-padding 0;

                &:hover {
                    background-color: transparent;
                    cursor: auto;
                }
            }
        }

        .pos-svg-icon--24 {
            float: left;
            fill: $color-c-l15;
        }
    }

    .cloud-popup__list--teaser {
        width: 304px;
        background: linear-gradient(135deg, $color-a 34px, $color-c-l15 34px);

        .cloud-popup__entry {
            color: $color-d;
            font-size: $cloud-font-size-medium;
        }

        .cloud-popup__title {
            flex-grow: 1;
            flex-shrink: 0;
            font-size: 16px;
        }

        &.cloud-popup__list--arrow {
            margin-top: 20px;

            &::after {
                position: absolute;
                bottom: 100%;
                left: 50%;
                width: 0;
                height: 0;
                margin-left: -15px;
                border: solid transparent;
                border-width: 15px;
                border-bottom-color: $color-c-l15;
                content: ' ';
                pointer-events: none;
            }
        }
    }

    .cloud-popup__entry--headline {
        display: flex;
        align-items: center;
        padding-left: 2 * $cloud-base-padding;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .cloud-popup__list--teaser,
    .cloud-popup__list--survey {
        .cloud-popup__entry {
            &.cloud-popup__entry--headline {
                display: flex;
                align-items: center;
                padding-left: 2 * $cloud-base-padding;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .cloud-popup__close-button {
            flex-grow: 0;
            flex-shrink: 0;
            margin: 0 0 0 auto;
            background-color: $color-c-l15;

            &:hover {
                background-color: $color-c-l15;
            }

            .pos-svg-icon--24 {
                float: left;
                fill: $color-c-l82;

                &:hover {
                    fill: $color-c-l50;
                }
            }
        }
    }

    .cloud-popup__buttons {
        display: flex;
    }

    .cloud-popup__entry--separator {
        margin: 0;

        hr {
            margin: 0;
            border-top: 1px solid $color-c-l82;
            flex-grow: 1;
        }
    }

    .cloud-popup__list--survey {

        width: 550px;
        background-color: $color-c-l15;

        @media only screen and (max-width: 600px) {
            width: 95%;
        }
    }

    .cloud-popup__list--teaser,
    .cloud-popup__list--survey,
    .cloud-popup__list--menu {
        .cloud-popup__entry--headline,
        .cloud-popup__entry--active {
            font-weight: bold;
        }

        .cloud-popup__entry--headline,
        .cloud-popup__entry--buttons {
            &:hover {
                background-color: transparent;
                cursor: auto;
            }
        }

        .cloud-popup__entry--no-padding {
            padding: 0;
        }
    }

    .cloud-popup__blocker {
        display: none;
        position: fixed;
        top: -10000px;
        right: -10000px;
        bottom: -10000px;
        left: -10000px;
        background-color: $cloud-popup-blocker-background-color;
        z-index: $cloud-zindex-popup;

        &.cloud-popup__blocker--visible {
            display: block;
        }
    }
}
