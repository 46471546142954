.cloud-tool-bar {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background: $color-d;
    flex-grow: 0;
    flex-shrink: 0;

    .cloud-tool-bar__hamburger {
        order: 1;

        &.cloud-tool-bar__hamburger--external,
        &.cloud-tool-bar__hamburger--edit-mode {
            display: none;
        }
    }

    .cloud-tool-bar__action-bar {
        flex-basis: $poseidon-button-width;
        flex-grow: 1;
        order: 2;
        width: 0;
        background-color: $color-c-l94;
        overflow: hidden;
    }

    .cloud-tool-bar__separator {
        flex-basis: 100%;
        order: 3;
        height: 1px;
    }

    .cloud-tool-bar__breadcrumb {
        flex-basis: $poseidon-button-width;
        flex-grow: 1;
        order: 4;
        width: 0;
    }

    .cloud-tool-bar__sort-menu {
        order: 5;
    }

    .cloud-tool-bar__view-toggle {
        order: 6;
    }

    &.cloud-tool-bar--edit-mode {
        & .cloud-tool-bar__separator {
            background-color: transparent;
        }
    }

    .cloud-filter-bar {
        order: 5;
    }
}

@media (max-width: $breakpoint-extra-small-max) {
    .cloud-tool-bar {
        background-color: $color-c-l94;

        .cloud-filter-bar {
            background-color: $white;
        }


        .cloud-tool-bar__action-bar {
            flex-grow: 0;
            order: 4;

            &.cloud-tool-bar__action-bar--edit-mode {
                flex-grow: 1;
            }
        }

        .cloud-tool-bar__separator {
            flex-basis: 0;
        }

        .cloud-tool-bar__breadcrumb {
            order: 2;

            &.cloud-tool-bar__breadcrumb--edit-mode {
                display: none;
            }

            &.cloud-tool-bar__breadcrumb--external {
                margin-left: 1.5 * $cloud-base-padding;
            }
        }

        .cloud-tool-bar__sort-menu {
            &.cloud-tool-bar__sort-menu--edit-mode {
                display: none;
            }
        }

        .cloud-tool-bar__view-toggle {
            &.cloud-tool-bar__view-toggle--edit-mode {
                display: none;
            }
        }

        &.cloud-tool-bar--edit-mode {
            background: $color-c-l15;
        }
    }
}

@media (min-width: $breakpoint-medium-landscape-min) {
    .cloud-tool-bar__hamburger {
        display: none;
    }
}
