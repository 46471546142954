cloud-dashboard {
    display: flex;
    flex-direction: column;

    cloud-tool-bar {
        flex-grow: 0;
        flex-shrink: 0;
    }

    cloud-scroll-area {
        flex-grow: 1;
    }

    .cloud-dashboard {
        max-width: 720px;
        padding: $cloud-base-padding;
        font-size: $cloud-font-size-medium;

        .cloud-dashboard__header {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-bottom: $cloud-base-padding * 2;
        }

        .cloud-dashboard__title {
            font-size: $cloud-base-layout-font;
            line-height: 24px;
            white-space: nowrap;
        }

        .cloud-dashboard__used {
            color: $color-c-l50;
            white-space: nowrap;
        }

        .cloud-dashboard__usage {
            width: 100%;
            height: 24px;
            margin-bottom: $cloud-base-padding;
            border-radius: $border-radius-base;
            background-image:
            linear-gradient($color-cloud-default-image, $color-cloud-default-image),
            linear-gradient($color-cloud-default-video, $color-cloud-default-video),
            linear-gradient($color-cloud-default-pdf, $color-cloud-default-pdf),
            linear-gradient($color-cloud-default-music, $color-cloud-default-music),
            linear-gradient($color-cloud-default-office, $color-cloud-default-office),
            linear-gradient($color-cloud-default-file, $color-cloud-default-file),
            linear-gradient($color-c-l94, $color-c-l94);
            background-repeat: no-repeat;
        }

        .cloud-dashboard__file-type-list {
            .pos-svg-icon {
                width: 32px;
                height: 32px;
            }
        }

        .cloud-dashboard__type {
            display: flex;
            align-items: center;
            height: 44px;
            padding: $cloud-base-padding;
        }

        .cloud-dashboard__type-name {
            margin-left: $cloud-base-025pct;
        }

        .cloud-dashboard__separator {
            margin-top: $cloud-base-padding * 3;
            margin-bottom: $cloud-base-padding * 2;
            border-top: 1px solid $color-c-l82;
        }

        .cloud-dashboard__extend-link {
            color: $color-b;
        }

        .cloud-dashboard__verify-trash {
            margin-bottom: $cloud-base-padding * 2;

            pos-message {
                width: 100%;
            }
        }
    }
}

