
cloud-whats-new-dialog {
    display: block;
    $image-height: 72px;
    $image-width: 136px;
    margin-left: $cloud-base-padding;
    margin-right: $cloud-base-padding;

    .cloud-whats-new__title {
        text-align: left;
        margin-bottom: $cloud-base-padding * 2;
    }

    .cloud-whats-new__container {
        display: flex;
        flex-wrap: wrap;
    }

    .cloud-whats-new__text {
        display: inline-block;
        flex-basis: 332px;
        flex-grow: 1;
        text-align: left;
    }

    .cloud-whats-new__image {
        display: block;
        flex-grow: 1;
        float: left;
        width: 224px;
        height: 100%;
        padding-right: $cloud-base-padding * 2;
        text-align: center;

        .pos-svg-icon {
            width: 100%;
            height: 100%;
            max-width: $image-width;
            max-height: $image-width;
        }
    }
}
