$dialog-margin: 8px;
$dialog-small-width: calc(100vw - #{2 * $dialog-margin});

.pos-dialog {
    display: flex;
    flex-direction: column;
    min-width: 320px - 2 * $dialog-margin;
    max-width: $dialog-small-width;
    max-height: 100vh;
    margin: $dialog-margin;
    border: 1px solid $modal-content-border-color;
    border-radius: $border-radius-base;
    @include box-shadow(0 5px 15px rgba(0, 0, 0, 0.5));
    background-color: $color-d;

    @include media-query(large) {
        max-width: 624px;
    }
}

.pos-dialog--size8 {
    width: 624px;
}

.pos-dialog--size7 {
    width: 544px;
}

.pos-dialog--size6 {
    width: 464px;
}

.pos-dialog--size5 {
    width: 384px;
}

.pos-dialog--size4 {
    width: 304px;
}

.pos-dialog__scroll-container {
    margin-bottom: 32px;
    overflow: auto;
}

.pos-dialog__content {
    margin: 0 16px;
}
