cloud-malware-icon {

    cursor: pointer;

    .pos-svg-icon {
        svg {
            fill: $color-h1;
        }
    }

}
