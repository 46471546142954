cloud-spinner-dialog {
    display: flex;
    flex-direction: column;
    align-items: center;

    .cloud-spinner-dialog__text {
        width: 100%;
        padding-top: 20px;
        text-align: center;
    }
}
