cloud-description-field {
    display: flex;
    position: absolute;
    bottom: $cloud-base-padding * 10;
    justify-content: center;
    width: 100%;
    z-index: $cloud-zindex-description-field;

    .cloud-description-field {
        span {
            display: inline-block;
            max-width: 300px;
            padding: $cloud-base-padding * 2;
            border-radius: $border-radius-base;
            background-color: $cloud-shadow;
            color: $white;
            word-wrap: break-word;
        }
    }

    .cloud-description-field--edit {
        display: flex;
        justify-content: center;
        width: 100%;

        .cloud-description-field--blocker {
            position: fixed;
            top: 0;
            width: 100%;
            height: 100%;
            z-index: -1;
        }

        textarea {
            width: 80%;
            height: 88px;
            text-align: center;
            word-wrap: break-word;
        }
    }
}

