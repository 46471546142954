cloud-tree-node {
    .cloud-tree-node {
        display: flex;
        align-items: center;
        height: $cloud-base-200pct;
        margin-right: $cloud-base-padding;
        margin-bottom: $cloud-base-padding;
        border-radius: $border-radius-base;
        cursor: pointer;

        &.cloud-dnd-over,
        &:hover {
            background-color: $color-d;
        }

        &:hover .cloud-tree-node__icon {
            fill: $color-b1;
        }

        .cloud-tree-node__name {
            @extend %cloud-text-ellipsis;

            padding-left: $cloud-base-padding;
            color: $color-c;
        }

        .cloud-tree-node__icon {
            fill: $color-b;
        }
    }

    .cloud-tree-node--active {
        background-color: $color-c-l82;
        color: $color-f;

        &:hover {
            background-color: $color-d;
        }

        &.cloud-dnd-over {
            .cloud-tree-node__icon {
                fill: $color-c;
            }

            .cloud-tree-node__name {
                color: $color-c;
            }

        }

        .cloud-tree-node__icon {
            fill: $color-b1;
        }
    }

    .cloud-tree-node--dnd-over * {
        pointer-events: none;
    }

    .cloud-tree-node__children {
        padding-left: 8px;

        &.cloud-tree-node__children--root-level {
            padding: 0;
        }
    }
}
