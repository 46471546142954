cloud-logout-button {
    height: 100%;

    .cloud-logout-button__container {
        width: 50px;
        height: 100%;
        padding: 12px;

        pos-svg-icon {
            fill: $color-t;
        }
    }

    .cloud-logout-button__icon {
        margin-top: 2px;
    }
}
